<template>
  <div>
    <div v-for="(option, index) of questionOptions" :key="index">
      <button
        class="btn btn-block text-left pl-4 mb-2"
        :class="{
          'btn-primary': selectedIndexes.indexOf(index) >= 0,
          'btn-outline-secondary': selectedIndexes.indexOf(index) < 0,
        }"
        style="white-space: normal"
        @click="toggleSelected(index)"
      >
        {{ option }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'MultipleSelection',
  data: () => ({
    selectedIndexes: [],
  }),
  computed: {
    ...mapState([
      'customQuestionList',
      'currentQuestionIndex',
      'customQuestionAnswers',
    ]),
    questionOptions() {
      return this.customQuestionList[this.currentQuestionIndex].questionData
        .options
    },
  },
  methods: {
    ...mapMutations(['storeAnswer', 'setQuestionStartTime']),
    toggleSelected(optionIndex) {
      const selectedIndex = this.selectedIndexes.indexOf(optionIndex)
      if (selectedIndex >= 0) {
        this.selectedIndexes.splice(selectedIndex, 1)
      } else {
        this.selectedIndexes.push(optionIndex)
      }
      this.selectedIndexes.sort()
      this.storeAnswer(this.selectedIndexes)
      this.$emit('validate')
    },
  },
  mounted() {
    this.setQuestionStartTime()
    this.selectedIndexes =
      this.customQuestionAnswers[this.currentQuestionIndex] || []
  },
}
</script>

<style scoped>
.selected {
  background-color: var(--primary) !important;
  color: white !important;
}
.btn.btn-outline-secondary {
  color: black !important;
}
.btn-outline-secondary:hover {
  background-color: white;
}
.btn:focus,
.btn:active {
  outline: none;
  box-shadow: none;
}
</style>
