<template>
  <div class="rewards-points">
    <div class="card">
      <div class="card-body text-center">
        <h1>{{ availablePoints }}</h1>
        <h3>Points</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'RewardsPoints',
  computed: {
    ...mapGetters('loyalty', ['availablePoints']),
  },
}
</script>

<style scoped lang="scss">
h1 {
  font-size: 40px;
}
h3 {
  margin-bottom: 5px;
}
</style>
