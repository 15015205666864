<template>
  <div>
    <RatingOptions @selected="updateSurveyRating" />
    <form @submit.prevent="submitSurveyFeedback">
      <div
        class="feedback-card card"
        :class="['rating-' + selectSurvey.rating + '-' + numOptions]"
      >
        <div class="card-header text-left">
          <h4 id="feedback" class="card-header-title">{{ feedbackMessage }}</h4>
        </div>
        <div class="text-center card-body">
          <div>
            <textarea
              v-model="feedback"
              aria-labelledby="feedback"
              class="form-control form-control-flush"
              rows="5"
              :placeholder="$t('leave_feedback.form.text_area.placeholder')"
              :disabled="loading"
            ></textarea>
          </div>
        </div>

        <div class="card-footer">
          <div class="d-flex mb-2 ml-1" style="height: 15px">
            <div class="mt--2 mr-2" style="font-size: 13px">
              {{ $t('leave_feedback.form.detail_meter.title') }}
            </div>
            <div class="w-50">
              <div class="progress" style="max-height: 10px">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style="background-color: var(--primary)"
                  :style="`width: ${progress}%`"
                ></div>
              </div>
            </div>
            <div v-if="progress === 100" class="ml-2 mt--2">🎉</div>
          </div>

          <button
            type="submit"
            class="btn btn-lg btn-block btn-primary mb-0"
            :class="{ 'is-loading': loading }"
            :disabled="!formValid || loading"
          >
            {{ $t('leave_feedback.form.button.submit') }}
          </button>
          <Alert v-if="errorMessage" type="alert-danger">
            {{ errorMessage }}
          </Alert>
        </div>
      </div>
    </form>
    <div v-if="errorMessage" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, createNamespacedHelpers } from 'vuex'
import RatingOptions from './RatingOptions'
import _ from 'lodash'

const SurveyModule = createNamespacedHelpers('SurveyModule')

export default {
  name: 'LeaveSurveyFeedback',
  components: {
    RatingOptions,
  },
  computed: {
    ...SurveyModule.mapGetters(['selectSurvey']),
    ...mapGetters(['getCustomOptionMsg', 'selectHasError', 'selectIsLoading']),
    loading() {
      return this.selectIsLoading(['MODIFY_SURVEY_RATING', 'SUBMIT_FEEDBACK'])
    },
    errorMessage() {
      return this.selectHasError(['MODIFY_SURVEY_RATING', 'SUBMIT_FEEDBACK'])
        ?.message
    },
    progress() {
      if (this.feedback.trim() === '') {
        return 0
      } else {
        const wordCount = this.feedback.trim().split(/\s+/).length
        const progress = (wordCount / 25) * 100
        return progress > 100 ? 100 : progress
      }
    },
    numOptions() {
      return !_.isEmpty(this.selectCustomOptions)
        ? this.selectCustomOptions.options.length
        : 5
    },
    formValid() {
      return this.feedback.trim().length > 3
    },
    feedbackMessage() {
      switch (this.selectSurvey?.rating) {
        case 1:
          return this.$t('leave_feedback.form.title.rating_1')
        case 2:
          return this.$t('leave_feedback.form.title.rating_2')
        case 3:
          return this.$t('leave_feedback.form.title.rating_3')
        default:
          return this.$t('leave_feedback.form.title.rating_4')
      }
    },
  },
  data: () => ({
    feedback: '',
  }),
  methods: {
    ...mapActions(['setErrorMessage']),
    ...SurveyModule.mapActions(['modifySurveyRating', 'submitFeedback']),
    async updateSurveyRating(rating) {
      const response = await this.modifySurveyRating({
        rating,
      })
      if (response?.nextToken) {
        this.$router.replace({
          name: 'landing',
          query: {
            token: response.nextToken,
            language: this.$route.query.language,
          },
        })
      }
    },
    async submitSurveyFeedback() {
      const response = await this.submitFeedback({
        feedback: this.feedback,
      })
      if (response?.nextToken) {
        this.$router.replace({
          name: 'landing',
          query: {
            token: response.nextToken,
            language: this.$route.query.language,
          },
        })
      }
    },
  },
  mounted() {
    if (this.selectSurvey && this.selectSurvey.feedback) {
      this.feedback = this.selectSurvey.feedback
    }
  },
}
</script>

<style scoped lang="scss">
.rating-1-5 {
  &::before,
  &::after {
    left: 8%;
  }
}
.rating-2-5 {
  &::before,
  &::after {
    left: 32%;
  }
}
.rating-3-5 {
  &::before,
  &::after {
    left: 50%;
  }
}
.rating-4-5 {
  &::before,
  &::after {
    left: 68%;
  }
}
.rating-5-5 {
  &::before,
  &::after {
    left: 92%;
  }
}
.rating-1-4 {
  &::before,
  &::after {
    left: 17%;
  }
}
.rating-2-4 {
  &::before,
  &::after {
    left: 39%;
  }
}
.rating-3-4 {
  &::before,
  &::after {
    left: 61%;
  }
}
.rating-4-4 {
  &::before,
  &::after {
    left: 83%;
  }
}
</style>
