<template>
  <div class="card-header">
    <ul class="nav nav-tabs nav-justified card-header-tabs">
      <li class="nav-item" v-for="tab of tabs" :key="tab.title">
        <a
          class="nav-link"
          :class="{ active: selected === tab.action }"
          href="javascript:;"
          @click="select(tab)"
          >{{ tab.title }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'RewardsTabs',
  data: () => ({
    selected: 'overview',
    tabs: [
      {
        title: 'Overview',
        action: 'overview',
      },
      {
        title: 'Account',
        action: 'account',
      },
    ],
  }),
  methods: {
    select(tab) {
      this.selected = tab.action
      this.$emit('selected', tab.action)
    },
  },
}
</script>

<style scoped lang="scss"></style>
