import LocationService from '../services/LocationService'

export default {
  namespaced: true,

  state: {
    location: {},
  },
  getters: {
    selectLocation: (state) => state.location,
  },
  mutations: {
    SET_LOCATION(state, location) {
      state.location = location || {}
    },
  },
  actions: {
    setLocation({ commit }, location) {
      commit('SET_LOCATION', location)
    },
    async locationLookup({ commit }, query) {
      const locationLookupResult = await LocationService.lookupLocation(query)
      return locationLookupResult?.data?.data?.locationId
    },
  },
}
