<template>
  <MainContent align-default>
    <Rewards />
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import Rewards from '@/components/Modules/Loyalty/Rewards/Rewards'

export default {
  name: 'RewardsPage',
  components: {
    MainContent,
    Rewards,
  },
}
</script>

<style scoped lang="scss"></style>
