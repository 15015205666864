<template>
  <MainContent>
    <BrandingHeader />
    <Redeem />
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import BrandingHeader from '@/components/Modules/Branding/BrandingHeader'
import Redeem from '@/components/Modules/Redeem/Redeem'

export default {
  name: 'RedeemPage',
  components: {
    MainContent,
    Redeem,
    BrandingHeader,
  },
}
</script>
