import Vue from 'vue'
import Vuex from 'vuex'
import { cloneDeep } from 'lodash'
import moment from 'moment-timezone'
import CompanyService from '@/services/CompanyService'
import CustomerService from '@/services/CustomerService'
import CustomQuestionService from '@/services/CustomQuestionService'
import LocationService from '@/services/LocationService'
import PromoService from '@/services/PromoService'
import TokenService from '@/services/TokenService'
import router from '../router'
import Theme from '../lib/theme'
import loyalty from './loyalty'
import promo from './promo'
import AnalyticEventModule from './analytic-event'
import CompanyModule from './company'
import CustomerModule from './customer'
import LocationModule from './location'
import SurveyModule from './survey'
import ReviewSubscriptionModule from './review-subscription'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import I18nModule from './i18n'
import { i18n } from '../setup/i18n-setup'

Vue.use(Vuex)

export const storeConfig = {
  state: {
    token: null,
    landingRoute: null,
    sources: [],
    customOptions: {},
    loader: [],
    errors: [],
    mixpanelPayload: {},
    componentConfig: {},
    // deprecated
    rating: null,
    company: {},
    location: {},
    customer: {},
    leftFeedback: false,
    surveyId: undefined,
    customerId: undefined,
    source: undefined,
    orderId: null,
    rawExperienceId: null,
    givenPromos: {},
    customOptionsChecked: false,
    // custom question states below this
    customQuestionList: [],
    customQuestionListId: '',
    currentQuestionIndex: 0,
    customQuestionAnswers: [],
    customQuestionSettings: {},
    questionStartTime: null,
    answerTimes: [],
    finishedAllQuestions: false,
    previouslyCompleted: false,
    isPreview: false,
    feedback: null,
  },
  mutations: {
    ADD_TO_LOADER(state, loadingEvent) {
      if (!state.loader.find((item) => item.uuid === loadingEvent.uuid)) {
        state.loader = [...state.loader, loadingEvent]
      }
    },
    REMOVE_FROM_LOADER(state, eventUuid) {
      state.loader = [...state.loader.filter((item) => item.uuid !== eventUuid)]
    },
    REMOVE_FROM_LOADER_BY_NAME(state, eventNames) {
      state.loader = [
        ...state.loader.filter((item) => !eventNames.includes(item.name)),
      ]
    },
    RESET_LOADER(state) {
      state.loader = []
    },
    ADD_TO_ERRORS(state, errorEvent) {
      if (!state.errors.find((item) => item.uuid === errorEvent.uuid)) {
        state.errors = [...state.errors, errorEvent]
      }
    },
    REMOVE_FROM_ERRORS(state, errorUuid) {
      state.errors = [...state.errors.filter((item) => item.uuid !== errorUuid)]
    },
    REMOVE_FROM_ERRORS_BY_NAME(state, errorNames) {
      state.errors = [
        ...state.errors.filter((item) => !errorNames.includes(item.name)),
      ]
    },
    RESET_ERRORS(state) {
      state.errors = []
    },
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_LANDING_ROUTE(state, route) {
      state.landingRoute = route
    },
    SET_SOURCES(state, sources) {
      state.sources = sources || []
    },
    SET_COMPONENT_CONFIG(state, config) {
      state.componentConfig = config
    },
    SET_CUSTOM_OPTIONS(state, options) {
      state.customOptions = options || {}
    },
    // depracted
    setIsPreview(state, status) {
      state.isPreview = status
    },
    setQuestionStartTime(state) {
      state.questionStartTime = new Date()
    },
    setAnswerTime(state) {
      const answerTime =
        new Date().getTime() - state.questionStartTime.getTime()
      state.answerTimes[state.currentQuestionIndex] =
        state.answerTimes[state.currentQuestionIndex] + answerTime || answerTime
    },
    markPreviouslyCompleted(state) {
      state.previouslyCompleted = true
    },
    setFinished(state) {
      state.finishedAllQuestions = true
    },
    changeCurrentIndex(state, change) {
      state.currentQuestionIndex = state.currentQuestionIndex + change
    },
    storeAnswer(state, answer) {
      const newAnswers = [...state.customQuestionAnswers]
      newAnswers[state.currentQuestionIndex] = answer
      state.customQuestionAnswers = newAnswers
    },
    setCustomQuestionListId(state, id) {
      state.customQuestionListId = id
    },
    setCompany(state, company) {
      state.company = cloneDeep(company)
      state.mixpanelPayload = {
        companyId: company._id,
        company: company.name,
      }
      if (state.company.settings.color) {
        Theme.applyColor(state.company.settings.color)
      }
      if (router.currentRoute.query.source) {
        state.mixpanelPayload.source = router.currentRoute.query.source
        state.source = router.currentRoute.query.source
      }
    },
    resetGivenPromos(state) {
      state.givenPromos = {}
    },
    setGivenPromo(state, payload) {
      const { _id: id } = payload
      state.givenPromos = {
        ...state.givenPromos,
        [id]: payload,
      }
    },
    setLocation(state, location) {
      state.location = cloneDeep(location)
      state.mixpanelPayload = {
        companyId: state.location.company._id,
        company: state.location.company.name,
        locationId: state.location._id,
        location: state.location.city,
      }
      if (state.location.company.settings.color) {
        Theme.applyColor(state.location.company.settings.color)
      }
      if (router.currentRoute.query.source) {
        state.mixpanelPayload.source = router.currentRoute.query.source
        state.source = router.currentRoute.query.source
      }
    },
    setLeftFeedback(state, value) {
      state.leftFeedback = value
    },
    setSurveyId(state, id) {
      state.surveyId = id
    },
    setCustomerId(state, id) {
      state.customerId = id
    },
    setCustomer(state, customer) {
      state.customer = customer
    },
    setCustomOptions(state, options) {
      state.customOptions = options || {}
    },
    setCustomQuestionList(state, questionList) {
      state.customQuestionList = questionList
    },
    setCustomQuestionAnswers(state, answerList) {
      state.customQuestionAnswers = answerList
    },
    setCustomQuestionSettings(state, settings) {
      state.customQuestionSettings = settings || {}
    },
    setOrderId: (state, orderId) => {
      state.orderId = orderId
    },
    SET_RAW_EXPERIENCE_ID: (state, rawExperienceId) => {
      state.rawExperienceId = rawExperienceId
    },
    SET_RATING: (state, rating) => {
      state.rating = rating
    },
    setFeedbackData: (state, feedback) => {
      state.feedback = feedback
    },
  },
  getters: {
    selectCompany: (state) => state.company,
    selectLocation: (state) => state.location,
    selectCustomer: (state) => state.customer,
    selectToken: (state) => state.token,
    selectLoader: (state) => state.loader,
    selectIsLoading: (state) => (eventNames) => {
      return state.loader.find((item) => eventNames.includes(item.name))
        ? true
        : false
    },
    selectLoadingEventByName: (state) => (name) => {
      return state.loader.find((item) => name === item.name)
    },
    selectErrors: (state) => state.errors,
    selectHasError: (state) => (eventNames) => {
      return state.errors.find((item) => eventNames.includes(item.name))
    },
    selectErrorByName: (state) => (name) => {
      return state.errors.find((item) => name === item.name)
    },
    selectMixPanelPayload: (state) => state.mixpanelPayload,
    selectLoading: (state) => state.loading,
    selectErrorMessage: (state) => state.errorMessage,
    selectSources: (state) => state.sources,
    selectSourceByName: (state) => (name) => {
      return state.sources.find(
        (source) => source.name.toLowerCase() === name.toLowerCase(),
      )
    },
    selectLandingRoute: (state) => state.landingRoute,
    selectComponentConfig: (state) => state.componentConfig,
    selectCustomOptions(state) {
      return state.customOptions
    },
    selectFeedbackSubmitText(state) {
      return state.customOptions.feedbackSubmitText
    },
    getCustomOptionMsg: (state) => (rating) => {
      const option = state.customOptions.options.find(
        ({ value }) => value === rating,
      )
      return option ? option.message : null
    },
    // deprecated
    getCustomQuestionList(state) {
      return state.customQuestionList
    },
    getCustomQuestionAnswers(state) {
      return state.customQuestionAnswers
    },
    getCurrentQuestionIndex(state) {
      return state.currentQuestionIndex
    },
    getCustomQuestionListId(state) {
      return state.customQuestionListId
    },
    selectCustomThanks(state) {
      return state.customQuestionSettings.customThanks
    },
    incentive(state, getters, rootState, rootGetters) {
      const { defaultIncentive = {}, incentives = [] } =
        state.location.company || state.company
      const language = rootGetters['i18n/selectLoadedLanguage']
      // TODO patching POORLY WRITTEN/Documented SOURCE logic
      const sourceName = (state.source || '')
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()

      const incentive = incentives.find(
        (i) =>
          i.sources.some(
            (s) => sourceName === s.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
          ) && i.language === language,
      )
      if (incentive) return incentive

      const fallbackIncentive = incentives.find(
        (i) =>
          i.sources.some(
            (s) => sourceName === s.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
          ) &&
          (!i.language || i.language === 'en'),
      )
      if (fallbackIncentive) return fallbackIncentive

      return defaultIncentive
    },
    callToActions(state) {
      return (state.location || {}).callToActions || []
    },
    selectCustomSubmitText(state) {
      return state.customOptions.submitText
    },

    getGivenPromoById: (state) => (id) => {
      return state.givenPromos[id]
    },
    selectGivenPromoRedemptionSpot: (state) => (id) => {
      const givenPromo = state.givenPromos[id]
      return givenPromo && givenPromo.redemptionSpot
    },
    getGivenPromoPromoCode: (state) => (id) => {
      const givenPromo = state.givenPromos[id]
      if (!givenPromo) {
        return undefined
      }
      const { promo, uniquePromoCode } = givenPromo
      if (!promo) {
        return undefined
      }
      const {
        inStorePromoCode,
        offPremisePromoCode,
        promoCode, // set from fetch unique promo code
        uniqueOffPremiseCode,
      } = promo
      if (givenPromo.redemptionSpot === 'off-premise') {
        return promoCode || uniqueOffPremiseCode || offPremisePromoCode
      } else if (givenPromo.redemptionSpot === 'in-store') {
        return (
          (uniquePromoCode && uniquePromoCode.code) ||
          promoCode ||
          inStorePromoCode
        )
      }
      return promoCode
    },
    /**
     * Select whether the GivenPromo with id is redeemed
     * @param {*} id
     * @returns
     */
    isGivenPromoRedeemed: (state) => (id) => {
      const givenPromo = state.givenPromos[id]
      return givenPromo && givenPromo.status === 'redeemed'
    },
    isGivenPromoExpired: (state) => (id) => {
      const givenPromo = state.givenPromos[id]
      if (!givenPromo || !givenPromo.promo) {
        return undefined
      }
      if (!givenPromo.promo.expirationDate) {
        return false
      }
      const expirationDate = moment(givenPromo.promo.expirationDate).endOf(
        'day',
      )
      return moment() > expirationDate
    },
    isGivenPromoRedeemable: (state, getters) => (id) => {
      const isRedeemed = getters.isGivenPromoRedeemed(id)
      if (!isRedeemed) {
        return true
      }
      const redeemedOn = getters.selectGivenPromoRedeemedOn(id)
      const now = moment()
      const expirationTime = getters.selectExpirationTime(id)
      const isWithinRedemptionWindow = now.isBefore(expirationTime)
      return redeemedOn && isWithinRedemptionWindow
    },
    selectGivenPromoRedeemedOn: (state) => (id) => {
      return state?.givenPromos?.[id]?.redeemedOn
    },
    /**
     * Select the time when this Given Promo expires
     */
    selectExpirationTime: (state, getters) => (id) => {
      const redemptionSpot = getters.selectGivenPromoRedemptionSpot(id)
      const redeemedOn = getters.selectGivenPromoRedeemedOn(id)
      const REDEMPTION_MINUTES = redemptionSpot === 'in-store' ? 15 : 120
      return moment(redeemedOn).add(REDEMPTION_MINUTES, 'minutes')
    },
    selectTimeLeftToExpiration: (state, getters) => (id) => {
      const expirationTime = getters.selectExpirationTime(id)
      const now = moment()
      return moment(expirationTime).diff(now).valueOf()
    },
    selectOrderId: (state) => {
      return state.orderId
    },
    selectRating: (state) => {
      return state.rating
    },
    selectRawExperienceId: (state) => {
      return state.rawExperienceId
    },
    selectCustomLeaveReview: (state) => {
      return {
        text: state.customOptions.leaveReviewText,
        color: state.customOptions.leaveReviewTextColor,
      }
    },
    selectCompany(state) {
      return state.company
    },
  },
  actions: {
    addToLoader({ commit }, loadingEvent) {
      loadingEvent.uuid = uuidv4()
      commit('ADD_TO_LOADER', loadingEvent)
      return loadingEvent.uuid
    },
    removeFromLoader({ commit }, eventUuid) {
      commit('REMOVE_FROM_LOADER', eventUuid)
    },
    removeFromLoaderByName({ commit }, eventNames) {
      commit('REMOVE_FROM_LOADER_BY_NAME', eventNames)
    },
    resetLoader({ commit }) {
      commit('RESET_LOADER')
    },
    addToErrors({ commit }, errorEvent) {
      errorEvent.uuid = uuidv4()
      commit('ADD_TO_ERRORS', errorEvent)
      return errorEvent.uuid
    },
    removeFromErrors({ commit }, eventUuid) {
      commit('REMOVE_FROM_ERRORS', eventUuid)
    },
    removeFromErrorsByName({ commit }, eventNames) {
      commit('REMOVE_FROM_ERRORS_BY_NAME', eventNames)
    },
    resetErrors({ commit }) {
      commit('RESET_ERRORS')
    },
    async decodeToken({ commit, dispatch, getters }, token) {
      if (getters.selectToken === token) return

      const loadingId = await dispatch('addToLoader', { name: 'DECODE_TOKEN' })

      try {
        commit('SET_TOKEN', token)
        const response = await TokenService.decodeToken()
        const {
          company,
          customer,
          customOptions,
          landingRoute,
          location,
          sources,
          survey,
          componentConfig,
        } = response.body.data

        commit('SET_COMPONENT_CONFIG', componentConfig)
        commit('SET_CUSTOM_OPTIONS', customOptions)
        commit('SET_SOURCES', sources)
        commit('SET_LANDING_ROUTE', landingRoute)

        const promises = [
          dispatch('CompanyModule/setCompany', company),
          dispatch('CustomerModule/setCustomer', customer),
          dispatch('LocationModule/setLocation', location),
          dispatch('SurveyModule/setSurvey', survey),
          dispatch(
            'ReviewSubscriptionModule/setReviewSubscriptions',
            location.subscriptions,
          ),
        ]

        await Promise.all(promises)
      } catch (e) {
        await dispatch('addToErrors', {
          name: 'DECODE_TOKEN',
          message: 'link_expired',
        })
      } finally {
        await dispatch('removeFromLoader', loadingId)
      }
    },
    // depracated
    setOrderId({ commit }, orderId) {
      commit('setOrderId', orderId)
    },
    setRawExperienceId({ commit }, rawExperienceId) {
      commit('SET_RAW_EXPERIENCE_ID', rawExperienceId)
    },
    setRating({ commit }, rating) {
      commit('SET_RATING', rating)
    },
    async getCompany({ state, commit }, companyId) {
      if (!companyId) return
      if (state.company._id === companyId) return

      const response = await CompanyService.get(companyId)
      const { company } = response.body
      commit('setCompany', company)
    },

    async getLocation({ state, commit }, locationId) {
      if (!locationId) return
      if (state.location._id === locationId) return

      const response = await LocationService.get(rerouteLocationId(locationId))
      const { location } = response.body

      commit('setLocation', location)
    },

    async getCustomer({ commit }, customerId) {
      const response = await CustomerService.getById(customerId)
      const { customer } = response.body
      if (customer) {
        commit('setCustomer', customer)
        commit('setCustomerId', customer._id)
      }
    },

    async getCustomOptions({ state, commit }, { companyId, source }) {
      const response = await CompanyService.customOptions({
        companyId,
        source: state.sources[0] || state.source,
      })
      commit('setCustomOptions', response.data)
      state.customOptionsChecked = true
    },

    async getCustomQuestionSettings({ state, commit }) {
      const response = await CustomQuestionService.getCustomQuestionSettings({
        companyId: state.company._id,
      })
      commit('setCustomQuestionSettings', response.data.settings)
    },

    async getCustomQuestionList({ commit, dispatch }, { listId }) {
      try {
        const response = await CustomQuestionService.getQuestionList({ listId })
        const {
          company,
          location,
          survey,
          customer,
          questionList,
          _id,
          completed,
          preview,
        } = response.data.questionListData

        // if already completed don't allow to retake
        if (completed) {
          await dispatch('getCompany', company)
          commit('markPreviouslyCompleted')
          return
        }

        commit('setIsPreview', preview || false)
        commit('setCustomQuestionList', questionList)
        commit('setSurveyId', survey)
        commit('setCustomQuestionListId', _id)
        await Promise.all([
          dispatch('getCompany', company),
          dispatch('getLocation', location),
          ...(!preview ? [dispatch('getCustomer', customer)] : []),
        ])
      } catch (error) {
        console.log(error)
      }
    },

    async clearGivenPromos({ commit }) {
      commit('resetGivenPromos')
    },
    async fetchUniquePromoCode(
      { commit, state },
      { customerId, givenPromoId, type },
    ) {
      const response = await PromoService.getUniqueCode({
        givenPromoId,
        promoType: type,
        customerId,
      })
      const { promoCode: code } = response.body
      commit('setGivenPromo', {
        ...state.givenPromos,
        [givenPromoId]: {
          ...state.givenPromos[givenPromoId],
          redemptionSpot: type,
          uniquePromoCode: { code, type },
        },
      })
    },
    async fetchGivenPromo(
      { commit },
      { customerId, givenPromoId, locationId },
    ) {
      try {
        const response = await PromoService.getGivenPromo({
          customerId,
          givenPromoId,
          locationId,
        })
        const { givenPromo } = response.body
        commit('setGivenPromo', givenPromo)
      } catch (error) {
        throw error
      }
    },
    async redeemGivenPromo(
      { dispatch },
      { givenPromoId, customerId, locationId, redemptionSpot },
    ) {
      await PromoService.redeem({
        customerId,
        givenPromoId,
        redemptionSpot,
      })
      dispatch('fetchGivenPromo', { customerId, givenPromoId, locationId })
    },

    async deleteAnswer({ getters }, deletedQuestion) {
      try {
        const query = {
          questionListId: getters.getCustomQuestionListId,
          questionId: deletedQuestion._id,
        }
        await CustomQuestionService.deleteAnswer(query)
      } catch (error) {
        console.log(error)
      }
    },

    async insertFollowUpQuestion({ getters, commit, dispatch }, question) {
      const questionList = _.cloneDeep(getters.getCustomQuestionList)
      questionList.splice(getters.getCurrentQuestionIndex + 1, 0, question)
      commit('setCustomQuestionList', questionList)
      dispatch('updateCustomQuestionList')

      // check if an answer space needs to be added
      if (
        getters.getCustomQuestionAnswers.length >
        getters.getCurrentQuestionIndex + 1
      ) {
        dispatch('insertAnswerIndex')
      }
    },

    async insertAnswerIndex({ getters, commit }) {
      const answerList = _.cloneDeep(getters.getCustomQuestionAnswers)
      answerList.splice(getters.getCurrentQuestionIndex + 1, 0, null)
      commit('setCustomQuestionAnswers', answerList)
    },

    async removeNextFollowUpQuestion({ getters, commit, dispatch }) {
      const questionList = _.cloneDeep(getters.getCustomQuestionList)
      const deletedQuestion = questionList[getters.getCurrentQuestionIndex + 1]
      questionList.splice(getters.getCurrentQuestionIndex + 1, 1)
      commit('setCustomQuestionList', questionList)
      dispatch('updateCustomQuestionList')

      // check if an answer space needs to be removed and database entry deleted
      if (
        getters.getCustomQuestionAnswers.length >
        getters.getCurrentQuestionIndex + 1
      ) {
        dispatch('removeAnswerIndex')
        dispatch('deleteAnswer', deletedQuestion)
      }
    },

    async removeAnswerIndex({ getters, commit }) {
      const answerList = _.cloneDeep(getters.getCustomQuestionAnswers)
      answerList.splice(getters.getCurrentQuestionIndex + 1, 1)
      commit('setCustomQuestionAnswers', answerList)
    },

    async updateCustomQuestionList({ getters }) {
      try {
        await CustomQuestionService.updateCustomQuestionList({
          questionListId: getters.getCustomQuestionListId,
          newQuestionList: getters.getCustomQuestionList.map((q) => q._id),
        })
      } catch (error) {
        console.log(error)
      }
    },

    async saveAnswer({ state }) {
      try {
        const answer = state.customQuestionAnswers[state.currentQuestionIndex]

        const questionType =
          state.customQuestionList[state.currentQuestionIndex].type

        const answerFields = {
          company: state.company._id,
          location: state.location._id,
          survey: state.surveyId,
          customer: state.customer._id,
          customQuestionList: state.customQuestionListId,
          customQuestion:
            state.customQuestionList[state.currentQuestionIndex]._id,
          type: questionType,
          answer: { [questionType]: answer },
          timeToAnswer: state.answerTimes[state.currentQuestionIndex],
        }

        await CustomQuestionService.saveAnswer({ answerFields })
      } catch (error) {
        console.log(error)
      }
    },

    async markCompleted({ state }) {
      try {
        if (state.customQuestionAnswers.length >= 1) {
          await CustomQuestionService.markCompleted({
            questionListId: state.customQuestionListId,
            companyId: state.company._id,
            locationId: state.location._id,
            customer: {
              _id: state.customer._id,
              phone: state.customer.phone,
            },
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
  modules: {
    AnalyticEventModule,
    CompanyModule,
    CustomerModule,
    LocationModule,
    loyalty,
    promo,
    SurveyModule,
    ReviewSubscriptionModule,
    i18n: I18nModule,
  },
}

function rerouteLocationId(locationId) {
  switch (locationId) {
    // reroute for Burger King
    case '5fe0dae35c19050008a67f0b':
      return '5fe0db1e34ac8200088cadba'
    default:
      return locationId
  }
}

export default new Vuex.Store(storeConfig)
