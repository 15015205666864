<template>
  <MainContent>
    <BrandingHeader />
    <Entered />
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import BrandingHeader from '@/components/Modules/Branding/BrandingHeader'
import Entered from '@/components/Modules/Locations/Entered'

export default {
  name: 'EnteredPage',
  components: {
    MainContent,
    BrandingHeader,
    Entered,
  },
}
</script>

<style scoped lang="scss"></style>
