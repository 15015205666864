<template>
  <div class="enter-giveaway">
    <h1 class="text-center mb-5" v-if="selectSourceByName('checkin')">
      {{ $t('giveaway.checkin.title') }}
    </h1>
    <h1 class="text-center mb-5">
      {{ incentivePrizeCTA }}
      <br />
      <strong class="text-primary" v-if="selectIncentive.type === 'instant'">
        {{ selectIncentive.prize }}.
      </strong>
    </h1>
    <form @submit.prevent="submit()">
      <div class="row">
        <div class="text-left form-group col-12 inline-block">
          <label for="phone">{{ $t('common.label.mobile_number') }}</label>
          <input
            id="phone"
            v-model="phone"
            ref="phone"
            @input="onPhoneType"
            type="tel"
            class="form-control"
            placeholder="(000) 000-0000"
            v-mask="'(###) ###-####'"
            autocomplete="tel"
            :disabled="loading || disablePhoneInput"
          />
        </div>
      </div>

      <FadeTransition>
        <div v-show="checkedPhone">
          <div class="row">
            <div class="text-left form-group col-12 inline-block">
              <label for="name">{{ $t('common.label.full_name') }}</label>
              <input
                id="name"
                ref="name"
                v-model="name"
                type="text"
                class="form-control"
                placeholder="John doe"
                autocomplete="name"
              />
            </div>
          </div>

          <div class="row">
            <div class="text-left form-group col-12 inline-block">
              <label for="email">
                {{ $t('common.label.email_address') }}
                <span class="text-muted">
                  {{
                    selectLocation.requireEmails
                      ? $t('common.single_word.required')
                      : $t('common.single_word.optional')
                  }}
                </span>
              </label>
              <input
                id="email"
                v-model="email"
                type="email"
                class="form-control"
                placeholder="name@address.com"
                autocomplete="email"
              />
            </div>
          </div>
          <div class="text-left mb-4">
            <small class="tight-text">
              {{ legalText }}
            </small>
          </div>
          <div class="row">
            <div class="col-12">
              <div v-if="errorMessage" class="alert alert-danger" role="alert">
                {{ errorMessage }}
              </div>
              <button
                type="submit"
                class="btn btn-lg btn-block btn-primary mb-3 text-capitalize"
                :class="{ 'is-loading': loading }"
                :disabled="!isFormValid"
              >
                {{ $t('giveaway.button.submit') }}
              </button>
            </div>
          </div>
        </div>
      </FadeTransition>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FadeTransition from '@/components/Transitions/FadeTransition'
import { createNamespacedHelpers } from 'vuex'
const CompanyModule = createNamespacedHelpers('CompanyModule')
const CustomerModule = createNamespacedHelpers('CustomerModule')
const LocationModule = createNamespacedHelpers('LocationModule')
const I18nModule = createNamespacedHelpers('i18n')

export default {
  name: 'EnterGiveaway',
  components: {
    FadeTransition,
  },
  data: () => ({
    name: '',
    phone: '',
    email: '',
    checkedPhone: false,
    disablePhoneInput: false,
  }),
  computed: {
    ...mapGetters(['selectSourceByName', 'selectIsLoading', 'selectHasError']),
    ...CompanyModule.mapGetters(['selectIncentive', 'selectCompany']),
    ...CustomerModule.mapGetters(['selectCustomer']),
    ...LocationModule.mapGetters(['selectLocation']),
    ...I18nModule.mapGetters(['selectLoadedLanguage']),
    loading() {
      return this.selectIsLoading(['CREATE_CUSTOMER'])
    },
    errorMessage() {
      return this.selectHasError(['CREATE_CUSTOMER'])?.message
    },
    incentivePrizeCTA() {
      switch (this.selectIncentive.type) {
        case 'instant':
          return this.$t('giveaway.incentive.call_to_action.instant')
        case 'sweepstakes':
        default:
          return this.$t('giveaway.incentive.call_to_action.default')
      }
    },
    isFormValid() {
      const isPhoneValid = this.validatePhone(this.phone)
      let isFormValid = !this.loading && isPhoneValid

      if (this.selectLocation.requireEmails) {
        isFormValid &= !!this.email
      }

      if (this.email) {
        isFormValid &= this.validateEmail(this.email)
      }

      return isFormValid
    },
    legalText() {
      if (this.isGrubhub) {
        return this.$t('giveaway.legal_text.grubhub', [
          this.$t('giveaway.button.submit'),
          this.selectCompany.name,
        ])
      } else {
        return this.$t('giveaway.legal_text.default', [
          this.$t('giveaway.button.submit'),
          this.selectCompany.name,
        ])
      }
    },
  },
  async mounted() {
    if (this.selectCustomer.phone) {
      this.phone = this.formatPhone(this.selectCustomer.phone)
      this.disablePhoneInput = true
      this.onPhoneType()
    }
  },
  methods: {
    ...CustomerModule.mapActions(['createCustomer']),
    formatPhone(phone) {
      phone = phone.replace('+', '').replace(/\D+/g, '')
      if (phone.charAt(0) === '1') {
        phone = phone.substr(1)
      }
      return phone
    },
    validateEmail(email) {
      const emailRegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/
      return emailRegExp.test(email.toLowerCase())
    },
    validatePhone(phone) {
      const stripped = (phone || '').replace(/\D/g, '')
      return /^\d{10}$/g.test(stripped)
    },
    async onPhoneType() {
      if (this.phone.length === 11) {
        this.phone = this.formatPhone(this.phone)
      }

      if (this.phone.length === 14) {
        this.checkedPhone = true

        if (!this.name) {
          this.$refs.name.focus()
        } else if (this.$refs.phone) {
          this.$refs.phone.blur()
        }
      }
    },
    async submit() {
      const response = await this.createCustomer({
        name: this.name,
        phone: this.phone,
        email: this.email,
        presumedLanguage: this.selectLoadedLanguage,
      })

      if (response?.nextToken) {
        this.$router.replace({
          name: 'landing',
          query: {
            token: response.nextToken,
            language: this.$route.query?.language,
          },
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.enter-giveaway {
  max-width: 300px;
  margin: auto;
}
.tight-text {
  display: block;
  line-height: 120% !important;
  font-size: 11px !important;
}
</style>
