<template>
  <div>
      <h3 class="display-4 text-center mb-4 question-title">
        {{ $t('leave_survey.rating.greeting') }}
      </h3>
      <RatingOptions @selected="selectedOption"/>
      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex'
import RatingOptions from './RatingOptions'

const SurveyModule = createNamespacedHelpers('SurveyModule')

export default {
  name: 'LeaveRating',
  components: {
    RatingOptions
  },
  computed: {
    ...mapGetters(['selectHasError', 'selectIsLoading']),
    errorMessage() {
      return this.selectHasError(['SAVE_SURVEY'])?.message
    },
  },
  methods: {
		...mapActions(['setErrorMessage']),
    ...SurveyModule.mapActions(['saveSurvey']),
    async selectedOption(rating) {
      const response = await this.saveSurvey({
        rating
      })
      if (response?.nextToken) {
        this.$router.push({
          name: 'landing',
          query: { token: response.nextToken, language: this.$route.query.language },
        })
      }
    }
  },
}
</script>