<template>
  <div role="listbox" class="feedback-options mb-4">
    <div
      role="option"
      @click="select(option)"
      v-for="option in options"
      :key="option.value"
      class="feedback-option avatar"
      :aria-selected="focusRating === option.value"
      :aria-label="`rating ${option.value}`"
      :class="{ unfocused: focusRating && focusRating !== option.value }"
      :style="disabled ? 'pointer-events: none' : ''"
    >
      <img
        :alt="`rating ${option.value} emoji`"
        :src="getEmojiSource(option)"
        class="avatar-img enlarge"
        @error="handleImgError(option)"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, createNamespacedHelpers } from 'vuex'

const SurveyModule = createNamespacedHelpers('SurveyModule')

export default {
  name: 'RatingOptions',
  data: () => ({
    disabled: false,
    defaultOptions: [
      {
        img: '1',
        value: 1,
      },
      {
        img: '2',
        value: 2,
      },
      {
        img: '3',
        value: 3,
      },
      {
        img: '4',
        value: 4,
      },
      {
        img: '5',
        value: 5,
      },
    ],
  }),
  computed: {
    ...mapGetters(['selectCustomOptions', 'selectMixpanelPayload']),
    ...SurveyModule.mapGetters(['selectSurvey']),
    options() {
      return Object.keys(this.selectCustomOptions).length && this.selectCustomOptions.options?.length
        ? this.selectCustomOptions.options
        : this.defaultOptions
    },
    focusRating() {
      return this.selectSurvey?.rating
    }
  },
  methods: {
    handleImgError(option) {
      this.$mixpanel.track('EmojiRenderError', {
        ...this.selectMixpanelPayload,
        customOptionsId: this.selectCustomOptions?._id || 'no custom options',
        ...(this.selectCustomOptions?.options
          ? this.selectCustomOptions.options.reduce((acc, curr) => {
            console.log('reducer', curr )
              const key = 'emojiOption' + curr.value
              acc[key] = JSON.stringify(curr)
              return acc
            }, {})
          : []),
        emojiNumber: option.img || option.value,
        component: 'RatingOptions',
      })
    },
    select(option) {
      if (!this.disabled) {
        this.disabled = true
        this.$emit('selected', option.value)
        let self = this
        setTimeout(() => {
          self.disabled = false
        }, 2000)
      }
    },
    getEmojiSource(option) {
      // if there are custom options return 'emoji' key's value
      if (this.selectCustomOptions && Object.values(this.selectCustomOptions).length)
        return option.emoji || '/theme/img/ratings/' + option.value + '.png'
      return '/theme/img/ratings/' + option.img + '.png'
    },
  }
}
</script>

<style scoped lang="scss">
.feedback-option {
  &.unfocused {
    opacity: 0.2;
    transform: scale(0.9);
  }
}
.enlarge {
  transform: scale(1.05);
}
</style>
