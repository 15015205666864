<template>
  <MainContent>
    <PromoRedirect />
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import PromoRedirect from '@/components/Modules/Redeem/PromoRedirect'

export default {
  name: 'DeprecatedRedeemPage',
  components: {
    PromoRedirect,
    MainContent,
  },
}
</script>

<style scoped lang="scss"></style>
