<template>
  <div class="alert" :class="[type]" role="alert">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: ['type'],
}
</script>

<style scoped lang="scss"></style>
