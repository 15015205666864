<template>
  <MainContent>
    <BrandingHeader v-if="showBrandingHeader"/>
    <LeaveRating v-if="showLeaveRating"/>
    <LeaveFeedback v-if="showLeaveFeedback"/>
  </MainContent>
</template>

<script>
import { mapGetters } from 'vuex'
import MainContent from '@/components/MainContent/MainContentV2'
import BrandingHeader from '@/components/Modules/Branding/BrandingHeaderV2'
import LeaveRating from '@/components/Modules/Survey/LeaveRating'
import LeaveFeedback from '@/components/Modules/Survey/LeaveFeedback'

export default {
  name: 'SurveyPage',
  components: {
    MainContent,
    BrandingHeader,
    LeaveRating,
    LeaveFeedback
  },
  computed: {
    ...mapGetters(['selectComponentConfig']),
    showBrandingHeader() {
      return this.selectComponentConfig?.survey?.BrandingHeader?.show === true
    },
    showLeaveRating() {
      return this.selectComponentConfig?.survey?.LeaveRating?.show === true
    },
    showLeaveFeedback() {
      return this.selectComponentConfig?.survey?.LeaveFeedback?.show === true
    }
  }
}
</script>