export default {
    namespaced: true,
    state: {
      reviewSubscriptions: [],
    },
    getters: {
      selectReviewSubscriptions: (state) => state.reviewSubscriptions
    },
    mutations: {
      SET_REVIEW_SUBSCRIPTIONS(state, reviewSubscriptions) {
        state.reviewSubscriptions = reviewSubscriptions || []
      },
    },
    actions: {
      async setReviewSubscriptions({ commit }, subscriptions) {
        let reviewSubscriptions = []

        if (Array.isArray(subscriptions)) {
          reviewSubscriptions = subscriptions.map(s => s.reviewSubscription)
        }
        commit('SET_REVIEW_SUBSCRIPTIONS', reviewSubscriptions)
      },
    },
  }
  