<template>
  <div class="order">
    <div class="card">
      <div class="card-body text-left">
        <h2 class="card-title text-center text-primary mt-2">Order Ahead</h2>
        <h3 class="card-title text-center mt-3 mb-0">
          Choose an ordering service
        </h3>
      </div>

      <div class="card-footer">
        <div class="row align-items-center">
          <div v-for="platform in platforms" :key="platform._id" class="col-12">
            <button
              @click="order(platform)"
              class="btn btn-lg btn-block btn-white mb-3 text-left brand"
            >
              <!-- <img :src="platform.img" class="avatar-img avatar-xs rounded mr-2" /> -->
              <span>{{ platform.name }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import AnalyticsService from '@/services/AnalyticsService'

export default {
  name: 'Order',
  computed: {
    ...mapState(['location', 'customerId', 'mixpanelPayload']),
    platforms() {
      return this.location.orderPlatforms
    },
  },
  methods: {
    order(platform) {
      // AnalyticsService.event({
      //   type: 'left-order',
      //   platformId: platform._id,
      //   companyId: this.location.company._id,
      //   locationId: this.location._id,
      //   customerId: this.customerId
      // })

      this.$mixpanel.track('Opening an Order Platform', {
        ...this.mixpanelPayload,
        platform: platform.name,
        url: platform.orderUrl,
      })

      // this.$router.push({
      //   name: 'giveaway',
      //   params: { locationId: this.location._id }
      // })

      if (platform.orderUsesPopup) {
        window.open(platform.orderUrl, '_blank')
      } else {
        window.location.href = platform.orderUrl
      }
    },
  },
  mounted() {
    this.$mixpanel.track('Order Platform Prompt', {
      ...this.mixpanelPayload,
    })
  },
}
</script>

<style scoped lang="scss">
.order {
  max-width: 300px;
  margin: auto;
}
</style>
