<template>
  <MainContent>
    <!-- <BrandingHeader show-location-name /> -->
    <FullLoader v-if="showLoader && !initialized && !errMessage" />
    <Menu />
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
// import BrandingHeader from '@/components/Modules/Branding/BrandingHeader'
import FullLoader from '@/components/MainContent/FullLoader'
import Menu from '@/components/Modules/Menu/Menu'

export default {
  name: 'MenuPage',
  components: {
    MainContent,
    // BrandingHeader,
    FullLoader,
    Menu,
  },
}
</script>

<style scoped lang="scss"></style>
