<template>
  <div>
    <div v-if="errMessage" class="alert alert-danger mb-3">
      {{ errMessage }}
    </div>
    <RedemptionCompletion
      v-if="step === 'redeemed'"
      :givenPromo="givenPromo"
      :showCountdown="showCountdown"
      :postCountdown="refresh"
    />
    <div class="redeem card">
      <RedemptionStart
        v-if="step === 'start'"
        :givenPromo="givenPromo"
        :isRedeemed="isRedeemed"
        :isExpired="isExpired"
        @redeem="step = 'confirm'"
      />
      <RedemptionChooseLocation
        @choose="choseSpot"
        v-if="step === 'choose-redemption-location'"
      />
      <RedemptionConfirm
        v-if="step === 'confirm'"
        :givenPromo="givenPromo"
        @confirmed="postConfirm"
      />
      <RedemptionRedeemed v-if="step === 'redeemed'" :givenPromo="givenPromo" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import AnalyticsService from '@/services/AnalyticsService'
import RedemptionStart from '@/components/Modules/Redeem/RedemptionStart'
import RedemptionConfirm from '@/components/Modules/Redeem/RedemptionConfirm'
import RedemptionChooseLocation from '@/components/Modules/Redeem/RedemptionChooseLocation'
import RedemptionRedeemed from '@/components/Modules/Redeem/RedemptionRedeemed'
import RedemptionCompletion from '@/components/Modules/Redeem/RedemptionCompletion'

export default {
  name: 'Redeem',
  components: {
    RedemptionCompletion,
    RedemptionRedeemed,
    RedemptionConfirm,
    RedemptionStart,
    RedemptionChooseLocation,
  },
  computed: {
    ...mapState([
      'customerId',
      'customer',
      'company',
      'location',
      'mixpanelPayload',
    ]),
    ...mapGetters([
      'getGivenPromoById',
      'selectGivenPromoRedemptionSpot',
      'isGivenPromoExpired',
      'isGivenPromoRedeemed',
      'selectCustomer',
    ]),
    companyId() {
      return (this.company || {})._id || this.location.company._id
    },
    givenPromo() {
      const { givenPromoId: id } = this.$route.params
      return this.getGivenPromoById(id)
    },
    isRedeemed() {
      const { givenPromoId: id } = this.$route.params
      return this.isGivenPromoRedeemed(id)
    },
    isExpired() {
      const { givenPromoId: id } = this.$route.params
      return this.isGivenPromoExpired(id)
    },
    redemptionSpot() {
      const { givenPromoId: id } = this.$route.params
      return this.selectGivenPromoRedemptionSpot(id)
    },
    showCountdown() {
      return this.redemptionSpot === 'in-store'
    },
  },
  data: () => ({
    errMessage: null,
    expirationDate: null,
    step: '',
  }),
  methods: {
    ...mapActions([
      'fetchGivenPromo',
      'fetchUniquePromoCode',
      'redeemGivenPromo',
    ]),
    async getUniquePromoCode(type) {
      const { customerId } = this.$route.params
      const { _id: givenPromoId } = this.givenPromo
      await this.fetchUniquePromoCode({
        customerId,
        givenPromoId,
        type,
      })
    },
    async postConfirm() {
      try {
        const { promo } = this.givenPromo

        // Mixpanel
        this.$mixpanel.track('Promo Redemption Occurred.', {
          ...this.mixpanelPayload,
          usesInStoreAndOffPremiseCodes: promo.usesInStoreAndOffPremiseCodes,
          hasUniqueInStoreCodes: promo.hasUniqueInStoreCodes,
          hasUniqueOffPremiseCodes: promo.hasUniqueOffPremiseCodes,
          inStorePromoCode: promo.inStorePromoCode,
          offPremisePromoCode: promo.offPremisePromoCode
        })

        if (promo.usesInStoreAndOffPremiseCodes) {
          this.step = 'choose-redemption-location'
          return
        }
        let redemptionSpot
        if (promo.hasUniqueInStoreCodes) {
          await this.getUniquePromoCode('in-store')
          redemptionSpot = 'in-store'
        } else if (promo.hasUniqueOffPremiseCodes) {
          await this.getUniquePromoCode('off-premise')
          redemptionSpot = 'off-premise'
        } else if (promo.inStorePromoCode) {
          redemptionSpot = 'in-store'
        } else if (promo.offPremisePromoCode) {
          redemptionSpot = 'off-premise'
        } else {
          // TODO should log this data to backend for investigation
          console.log('Defaulting to in-store redemption...')
          redemptionSpot = 'in-store'
        }
        await this.redeem(redemptionSpot)
      } catch (err) {
        this.errMessage = err && err.body && err.body.message
      }
    },
    async choseSpot(redemptionSpot) {
      const { promo } = this.givenPromo
      try {
        if (redemptionSpot === 'in-store') {
          if (promo.hasUniqueInStoreCodes) {
            await this.getUniquePromoCode('in-store')
          }
        } else {
          if (promo.hasUniqueOffPremiseCodes) {
            await this.getUniquePromoCode('off-premise')
          }
        }
        await this.redeem(redemptionSpot)
      } catch (err) {
        this.errMessage = err && err.body && err.body.message
      }
    },
    async redeem(redemptionSpot) {
      const { campaignId, customerId, givenPromoId, locationId } =
        this.$route.params
      const { promo } = this.givenPromo
      try {
        // API Request
        await this.redeemGivenPromo({
          givenPromoId,
          customerId,
          locationId,
          redemptionSpot,
        })

        // Mixpanel
        this.$mixpanel.track('Redeemed Promo', {
          ...this.mixpanelPayload,
          source: this.$route.query.source,
          customer: this.selectCustomer?._id,
          redemptionSpot
        })

        AnalyticsService.event({
          type: 'redeem-promo',
          companyId: this.companyId,
          locationId: this.location._id || locationId,
          promoId: promo._id,
          givenPromoId: this.givenPromo._id || givenPromoId,
          customerId,
          campaignId,
        })
      } catch (error) {
        const { message } = error.body
        window.alert(message)
      }

      this.isLoading = false
    },
    refresh() {
      window.location.reload()
    },
  },
  watch: {
    givenPromo: {
      immediate: true,
      handler() {
        if (!this.givenPromo) {
          this.step = ''
          return
        }
        let update = ''
        if (this.isRedeemed) {
          update = 'redeemed'
        } else {
          update = 'start'
        }
        this.step = update
      },
    },
  },
  async mounted() {
    this.$mixpanel.track('Redeem Promo Page', {
      ...this.mixpanelPayload,
      source: this.$route.query.source,
    })
    const { customerId, givenPromoId, locationId } = this.$route.params

    try {
      await this.fetchGivenPromo({
        customerId,
        givenPromoId,
        locationId,
      })

      AnalyticsService.event({
        customerId,
        type: 'click-promo',
        companyId: this.companyId,
        locationId: this.location._id,
        promoId: this.givenPromo.promo._id,
        givenPromoId: this.givenPromo._id,
      })
    } catch (err) {
      this.errMessage = err && err.body && err.body.message
    }
  },
}
</script>

<style scoped lang="scss">
.redeem,
.alert {
  max-width: 300px;
  margin: auto;
}
</style>
