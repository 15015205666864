<template>
  <MainContent>
    <BrandingHeader />
    <ShareFeedback />
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import BrandingHeader from '@/components/Modules/Branding/BrandingHeader'
import ShareFeedback from '../components/Modules/Locations/ShareFeedback'

export default {
  name: 'ShareFeedbackPage',
  components: {
    MainContent,
    BrandingHeader,
    ShareFeedback,
  },
  mounted() {},
}
</script>

<style scoped lang="scss"></style>
