<template>
  <MainContent>
    <BrandingHeader v-if="showBrandingHeader"/>
    <EnteredGiveaway v-if="showEnteredGiveaway && !selectCustomOptions.hideIncentiveConfirmation"/>
    <FeedbackAlert v-if="showFeedbackAlert"/>
    <ReviewsAndActions v-if="showReviewsAndActions && !selectCustomOptions.hideReviewPlatforms"/>
    <Confetti v-if="showConfetti"/>
  </MainContent>
</template>

<script>
import { mapGetters } from 'vuex'
import MainContent from '@/components/MainContent/MainContentV2'
import BrandingHeader from '@/components/Modules/Branding/BrandingHeaderV2'
import EnteredGiveaway from '@/components/Modules/Confirmation/EnteredGiveaway'
import FeedbackAlert from '@/components/Modules/Confirmation/FeedbackAlert'
import ReviewsAndActions from '@/components/Modules/Confirmation/ReviewsAndActions'
import Confetti from '@/components/Backgrounds/Confetti'

export default {
  name: 'ConfirmationPage',
  components: {
    MainContent,
    BrandingHeader,
    EnteredGiveaway,
    FeedbackAlert,
    ReviewsAndActions,
    Confetti
  },
  computed: {
    ...mapGetters(['selectComponentConfig', 'selectCustomOptions']),
    showBrandingHeader() {
      return this.selectComponentConfig?.confirmation?.BrandingHeader?.show === true
    },
    showEnteredGiveaway() {
      return this.selectComponentConfig?.confirmation?.EnteredGiveaway?.show === true
    },
    showFeedbackAlert() {
      return this.selectComponentConfig?.confirmation?.FeedbackAlert?.show === true
    },
    showReviewsAndActions() {
      return this.selectComponentConfig?.confirmation?.ReviewsAndActions?.show === true
    },
    showConfetti() {
      return this.selectComponentConfig?.confirmation?.Confetti?.show === true
    }
  }
}
</script>