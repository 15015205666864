import Vue from 'vue'

export default {
  create(payload) {
    return Vue.http.post('customer', payload)
  },
  getByPhone(payload) {
    return Vue.http.post('customer/get-by-phone', payload)
  },
  getById(customerId) {
    return Vue.http.post('customer/get-by-id', { customerId })
  },
  createCustomer(payload) {
    return Vue.http.post('v2/customer', payload)
  }
}
