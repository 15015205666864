<template>
  <div>
    <canvas v-if="isRedeemable" id="confetti" class="card-img-top"></canvas>
    <div class="redeem card card-primary mb-3">
      <div v-if="isRedeemable" class="card-body text-center pt-3 pb-3 px-0">
        <div v-if="promoCode" class="promo-code" @click="copyCode">
          <p class="mb-1">{{ $t('common.single_word.code') }}</p>
          <h1 class="mb-0">{{ promoCode }}</h1>
          <p
            v-if="postCopyText && givenPromo.redemptionSpot === 'off-premise'"
            class="mb-0 mt-2"
          >
            {{ postCopyText }}
          </p>
          <transition name="fade">
            <button
              v-if="showRedirect"
              @click.stop="redirect"
              class="btn btn-outline-primary btn-lg mt-3 mx-3 redirect"
            >
              {{ $t('redemption_completion.button.order') }}
            </button>
          </transition>
          <hr v-if="showCountdown" />
        </div>
        <div v-if="showCountdown">
          <p class="mb-1">{{ $t('redemption_completion.expires') }}</p>
          <h1 class="mb-0">
            <CountdownTimer :time="timeToExpiration" @end="handleCountdownEnd">
            </CountdownTimer>
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ConfettiGenerator from 'confetti-js'
import { mapGetters } from 'vuex'
import CountdownTimer from '@/components/Modules/Redeem/CountdownTimer'

export default {
  name: 'RedemptionCompletion',
  components: { CountdownTimer },
  props: ['givenPromo', 'showCountdown', 'postCountdown'],
  data: () => ({
    postCopyText: '',
    showRedirect: false,
  }),
  mounted() {
    this.showConfetti()
    this.$mixpanel.track('RedemptionCompletion', {
      ...this.selectMixPanelPayload,
      customer: this.selectCustomer?._id,
    })
    this.postCopyText = this.$t('common.utility.copy')
  },
  computed: {
    ...mapGetters([
      'getGivenPromoPromoCode',
      'isGivenPromoRedeemable',
      'selectTimeLeftToExpiration',
      'selectMixPanelPayload',
      'selectCompany',
      'selectLocation',
      'selectCustomer',
    ]),
    promoCode() {
      const code = this.getGivenPromoPromoCode(
        this.givenPromo && this.givenPromo._id,
      )
      if (!code) {
        this.$mixpanel.track('Missing Promo Code', {
          ...this.selectMixPanelPayload,
          givenPromo: this.givenPromo,
          company: this.selectCompany?._id || this.selectLocation?.company?._id,
          location: this.selectLocation,
        })
      }
      return code
    },
    isRedeemable() {
      const { _id: id = null } = this.givenPromo || {}
      return this.isGivenPromoRedeemable(id)
    },
    timeToExpiration() {
      const { _id: id = null } = this.givenPromo || {}
      return this.selectTimeLeftToExpiration(id)
    },
  },
  methods: {
    showConfetti() {
      try {
        const confettiSettings = { target: 'confetti' }
        const confetti = new ConfettiGenerator(confettiSettings)
        confetti.render()
      } catch (error) {
        console.log('Error', error)
      }
    },
    handleCountdownEnd() {
      this.postCountdown()
    },
    async sleep(time) {
      await new Promise((resolve) => setTimeout(resolve, time))
    },
    async copyCode() {
      if (this.givenPromo.redemptionSpot !== 'off-premise') {
        return
      }

      await this.$copyText(this.promoCode)
      this.postCopyText = this.$t('common.utility.copied')

      if (this.givenPromo.promo.offPremiseLocationUrl) {
        await this.sleep(1000)
        this.showRedirect = true
        this.postCopyText = null
      }

      this.$mixpanel.track('CopiedRedemptionCode', {
        ...this.selectMixPanelPayload,
        customer: this.selectCustomer?._id,
      })
    },
    redirect() {
      this.$mixpanel.track('ClickedOrderHere', {
        ...this.selectMixPanelPayload,
        customer: this.selectCustomer?._id,
      })

      let url = this.givenPromo.promo.offPremiseLocationUrl || ''
      url = url.startsWith('http') ? url : 'https://' + url

      window.open(url, '_blank')
    },
  },
}
</script>
<style scoped lang="scss">
.redeem {
  max-width: 300px;
  margin: auto;
}

.card-primary {
  color: #fff;

  h1 {
    font-size: 30px;
    line-height: 33px;
  }
}

.promo-code {
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.redirect {
  font-weight: bold;
  width: stretch;
  color: var(--priamry);

  &:focus,
  &:active {
    box-shadow: 0 0 0 0.15rem !important;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
