const isProduction = process.env.NODE_ENV === 'production'
const isTest = process.env.NODE_ENV === 'test'

export default {
  intercom: isProduction ? 'tbu5bls0' : 'ix7dwy5j',
  apiv2: {
    production: 'https://api.ovationup.com/surveys',
    staging: 'https://api-staging.ovationup.com/surveys',
    test: 'https://api-test.ovationup.com/surveys'
  },
  api: isProduction
    ? 'https://apis.ovationup.com/app/v1'
    : isTest
    ? 'https://sandbox-apis.ovationup.com/app/v1'
    : 'http://localhost:3000/app/v1',
  mixpanel: isProduction
    ? '47ec235d11db7e1c18db64246c4d36c4'
    : '9a11b06e2c855104a7dc5b739d8dce98',
  surveyApi: isProduction
    ? 'https://api.ovationup.com/survey-app/v2'
    : 'https://api-test.ovationup.com/survey-app/v2'
}
