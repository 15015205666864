<template>
  <div class="rewards-overview bump-up">
    <div
      v-if="$store.state.leftFeedback"
      class="alert alert-primary mb-3 mt-3 mr-3 ml-3"
      role="alert"
    >
      <i class="fe fe-send mr-1"></i>
      <strong>{{ feedbackSentThankYouText }}</strong> 
      {{ feedbackSentNotificationText }}
    </div>
    <div v-if="noRewards" class="card-body">
      <RewardsOffstate />
    </div>
    <div v-if="hasRewards" class="card-body">
      <RewardsPoints />
      <UpcomingRewards />
      <LoyaltyActivity />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RewardsOffstate from './RewardsOffstate'
import RewardsPoints from './RewardsPoints'
import UpcomingRewards from './UpcomingRewards'
import LoyaltyActivity from './LoyaltyActivity'

export default {
  name: 'RewardsOverview',
  components: {
    RewardsOffstate,
    RewardsPoints,
    UpcomingRewards,
    LoyaltyActivity,
  },
  computed: {
    ...mapGetters('loyalty', ['noRewards', 'hasRewards'], 'selectCustomOptions'),
    feedbackSentThankYouText() {
      return this.selectCustomOptions.feedbackSentThankYouText || 'Thank you!'
    },
    feedbackSentNotificationText() {
      return this.selectCustomOptions.feedbackSentNotificationText || 'Your feedback was sent to management for review.'
    },
  },
}
</script>

<style scoped lang="scss">
.bump-up {
  z-index: 1;
}
</style>
