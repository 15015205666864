<template>
  <MainContent>
    <BrandingHeader show-location-name />
    <Order />
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import BrandingHeader from '@/components/Modules/Branding/BrandingHeader'
import Order from '@/components/Modules/Order/Order'

export default {
  name: 'OrderPage',
  components: {
    MainContent,
    Order,
    BrandingHeader,
  },
}
</script>

<style scoped lang="scss"></style>
