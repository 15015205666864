<template>
  <FadeTransition>
    <div v-if="loaded" class="wrapper">
      <div class="card">
        <div class="card-body">
          <h1 class="card-title text-center my-3">How did we do?</h1>
          <h4 class="text-center text-muted">
            Choose the brand you ordered from.
          </h4>
        </div>

        <div class="card-footer">
          <div v-if="brands.length" class="row align-items-center">
            <div v-for="b in brands" :key="b.brandId" class="col-12">
              <button
                @click="pickBrand(b)"
                class="
                  d-flex
                  flex-wrap
                  align-items-center
                  btn btn-lg btn-block btn-white
                  my-2
                  text-left
                "
              >
                <div v-if="b.logo" class="flex-1 avatar avatar-sm mr-3 ml--2">
                  <img :src="b.logo" class="avatar-img rounded-circle" />
                </div>
                <div class="brand-name">{{ b.name }}</div>
              </button>
            </div>
          </div>
          <div v-else class="row align-items-center">No brands found!</div>
        </div>
      </div>
    </div>
    <FullLoader v-else />
  </FadeTransition>
</template>

<script>
import { mapState } from 'vuex'

import FullLoader from '@/components/MainContent/FullLoader'
import FadeTransition from '@/components/Transitions/FadeTransition'
import LocationService from '../services/LocationService'

export default {
  name: 'BrandsPage',

  components: {
    FullLoader,
    FadeTransition,
  },

  data() {
    return {
      loaded: false,
      locations: [],
    }
  },

  computed: {
    ...mapState(['mixpanelPayload']),

    brands() {
      const { b: bQuery } = this.$route.query
      const filteredBrands = bQuery
        ? Array.isArray(bQuery)
          ? bQuery
          : [bQuery]
        : null
      const brandsMap = {}
      const defaultLogo =
        'https://s3.us-east-2.amazonaws.com/assets.ovationup.com/mobile/logo.png'
      this.locations.forEach((l) => {
        if (l.status !== 'cancelled') {
          const brandId = l.company._id
          const logo = l.company.settings.logo
          brandsMap[brandId] = {
            brandId,
            locationId: l._id,
            name: l.company.name,
            logo: logo === defaultLogo ? null : logo,
          }
        }
      })

      let brands = Object.values(brandsMap)

      if (filteredBrands) {
        brands = brands.filter((bd) => filteredBrands.includes(bd.brandId))
      }

      return brands.sort((a, b) => {
        let nameA = a.name.toUpperCase() // ignore upper and lowercase
        let nameB = b.name.toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0 // names must be equal
      })
    },
  },

  watch: {
    brands(brands) {
      if (brands.length === 1) {
        this.pickBrand(brands[0])
      }
    },
  },

  async mounted() {
    try {
      const { addressId } = this.$route.params
      const { body } = await LocationService.getByAddress(addressId)

      this.locations = body.locations
      this.$mixpanel.track('Pick brands page', {
        ...this.mixpanelPayload,
      })
    } catch (error) {
      console.error(error)
    } finally {
      this.loaded = true
    }
  },

  methods: {
    pickBrand({ locationId }) {
      const { s, c } = this.$route.query
      this.$router.push({
        name: 'leave-survey',
        params: { locationId, customerId: c },
        query: { source: s },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  max-width: 320px;
  margin: auto;
}
.brand-name {
  flex: 1;
  white-space: normal;
  overflow-wrap: break-word;
}
</style>
