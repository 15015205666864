<template>
  <div class="order">
    <div class="card">
      <canvas id="confetti" class="card-img-top"></canvas>
      <div class="card-body text-left">
        <h3 class="card-title text-center mt-3 mb-0">
          {{ $t('leave_review.thank_you') }}
        </h3>
      </div>
      <div class="card-footer text-left">
        <div class="animation-container text-center">
          <img
            src="@/assets/animations/celebration.gif"
            alt="Animation"
            width="100"
            height="100"
          />
        </div>
        <p class="text-center mt-3">
          {{ $t('share_feedback.copy') }}
        </p>

        <div
          class="border rounded p-3 pb-1 mt-4"
          style="border: 1px solid #dee2e6"
        >
          <h5 class="username">{{ location.name }}</h5>
          <div class="rating">
            <span v-for="star in 5" :key="star" class="star">&#9733;</span>
          </div>
          <p style="margin: 0px" class="feedback-text mt-2">
            {{
              feedbackData ||
              'Here is some feedback text that describes the user’s experience.'
            }}
          </p>
        </div>
        <div
          v-if="platforms.length"
          class="d-flex justify-content-center flex-column mt-3 align-items-center"
        >
          <div v-for="platform in platforms" :key="platform._id" class="w-100">
            <button
              @click="review(platform)"
              class="btn btn-block btn-white mb-3 d-flex align-items-center w-100"
            >
              <img
                :src="platform.img"
                :alt="`${platform.name} logo`"
                class="avatar-img avatar-xxs rounded mr-2"
              />
              <span>{{ platform.name }}</span>
            </button>
          </div>
        </div>
        <hr class="separator" v-if="showSeparator" />
        <div
          v-if="callToActions.length"
          class="d-flex justify-content-center flex-column mt-3 align-items-center"
        >
          <div v-for="cta in callToActions" :key="cta._id" class="w-100 mb-3">
            <button
              @click="visitCTA(cta)"
              class="btn btn-block btn-white d-flex align-items-center w-100"
            >
              <img
                v-if="cta.logo"
                :src="cta.logo"
                :alt="`${cta.action} logo`"
                class="avatar-img avatar-xxs rounded mr-3"
              />
              <div class="text-left" style="white-space: normal">
                {{ cta.action }}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfettiGenerator from 'confetti-js'
import { mapState, createNamespacedHelpers, mapGetters } from 'vuex'
import { getPlatformDetails } from '@/lib/platforms'
import AnalyticsService from '@/services/AnalyticsService'
const I18nModule = createNamespacedHelpers('i18n')

export default {
  name: 'ShareFeedback',
  data() {
    return {
      lottieAnimation: null,
    }
  },
  computed: {
    ...mapState([
      'company',
      'source',
      'location',
      'customerId',
      'mixpanelPayload',
      'feedback',
    ]),
    ...mapGetters(['callToActions']),
    ...I18nModule.mapGetters(['selectLoadedLanguage']),
    platforms() {
      return (
        this.location?.subscriptions
          ?.filter((p) => !p.hidden)
          ?.map((platform) => getPlatformDetails(platform))
          ?.sort((a, b) => a.displayOrder - b.displayOrder) ?? []
      )
    },
    feedbackData() {
      return this.feedback
    },
    showSeparator() {
      return this.callToActions.length && this.platforms.length
    },
  },
  mounted() {
    const confettiSettings = { target: 'confetti' }
    const confetti = new ConfettiGenerator(confettiSettings)
    confetti.render()

    this.$mixpanel.track('Share Feedback Prompt', {
      ...this.mixpanelPayload,
    })
  },
  methods: {
    review(platform) {
      AnalyticsService.event({
        type: 'left-review',
        reviewSubscriptionId: platform._id,
        companyId: this.location.company._id,
        locationId: this.location._id,
        customerId: this.customerId,
      })
      this.$mixpanel.track('Leaving a Review', {
        ...this.mixpanelPayload,
        platform: platform.name,
        url: platform.reviewUrl,
      })
      let newWindow = window.open(
        platform.reviewUrl,
        '_blank',
        'noopener,noreferrer',
      )
      if (newWindow) newWindow.opener = null
    },
    visitCTA(cta) {
      this.$mixpanel.track('Visiting Call To Action', {
        ...this.mixpanelPayload,
        action: cta.action,
        url: cta.url,
      })
      let newWindow = window.open(cta.url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    },
  },
}
</script>

<style scoped lang="scss">
.animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.order {
  max-width: 300px;
  margin: auto;

  .username {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .rating {
    color: #ffcc00;
  }

  .feedback-text {
    margin-top: 10px;
    font-size: 14px;
    color: #6c757d;
  }

  .btn {
    width: 150px;
    &:hover {
      color: inherit;
      background-color: transparent;
    }
  }
}
</style>
