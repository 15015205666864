import Vue from 'vue'
import Router from 'vue-router'
import LandingPage from './pages/LandingPage'
import CustomerPage from './pages/CustomerPage.vue'
import ConfirmationPage from './pages/ConfirmationPage.vue'
import SurveyPage from './pages/SurveyPage.vue'
// depracated
import LeaveSurveyPage from './pages/LeaveSurveyPage'
import LeaveSurveyLocationLookupPage from './pages/LeaveSurveyLocationLookupPage'
import RedeemPage from './pages/RedeemPage'
import DeprecatedRedeemPage from './pages/DeprecatedRedeemPage'
import LeaveReviewPage from './pages/LeaveReviewPage'
import LeaveFeedbackPage from './pages/LeaveFeedbackPage'
import GiveawayPage from './pages/GiveawayPage'
import ShareFeedbackPage from './pages/ShareFeedbackPage'
import EnteredPage from './pages/EnteredPage'
import LoyaltyPage from './pages/LoyaltyPage'
import RewardsPage from './pages/RewardsPage'
import RedeemRewardPage from './pages/RedeemRewardPage'
import OrderPage from './pages/OrderPage'
import OrderLocationsPage from './pages/OrderLocationsPage'
import MenuPage from './pages/MenuPage'
import MenuLocationsPage from './pages/MenuLocationsPage'
import BrandsPage from './pages/BrandsPage'
import CustomQuestionsPage from './pages/CustomQuestionsPage'
import QRPage from './pages/QRPage'
import FauxInfoForm from './pages/FauxInfoForm'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== 'NavigationDuplicated') throw err
  })
}

Vue.use(Router)

const TEMPLATE_ENUMS = {
  SURVEY: 'survey',
  REDEEM: 'redeem',
}

export const routes = [
  {
    path: '/',
    name: 'landing',
    component: LandingPage,
    meta: { templates: TEMPLATE_ENUMS.SURVEY },
  },
  {
    path: '/customer',
    name: 'customer',
    component: CustomerPage,
    meta: { templates: TEMPLATE_ENUMS.SURVEY },
  },
  {
    path: '/survey',
    name: 'survey',
    component: SurveyPage,
    meta: { templates: TEMPLATE_ENUMS.SURVEY },
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    component: ConfirmationPage,
    meta: { templates: TEMPLATE_ENUMS.SURVEY },
  },
  //deprecated
  {
    path: '/brands/:addressId?',
    name: 'pick-brand',
    component: BrandsPage,
  },
  {
    path: '/leave-rating/:locationId/:customerId/:rating?',
    name: 'leave-rating',
    component: LeaveSurveyPage,
    meta: { templates: TEMPLATE_ENUMS.SURVEY },
  },
  {
    path: '/custom-questions/:listId',
    name: 'custom-questions',
    component: CustomQuestionsPage,
  },
  {
    path: '/leave-survey/:locationId/:customerId?',
    name: 'leave-survey',
    component: LeaveSurveyPage,
    meta: { templates: TEMPLATE_ENUMS.SURVEY },
    beforeEnter: (to, from, next) => {
      try {
        let source = to.query.source

        // Check if 'source' exists and contains an unintended URL
        if (source && source.includes('https://')) {
          // Extract the intended 'source' value before the space or URL
          const fixedSource = source.split('https://')[0]

          // Prevent infinite loop by checking if 'source' is already corrected
          if (source !== fixedSource) {
            // Redirect to the same path with the corrected 'source' parameter
            next({
              path: to.path,
              query: { ...to.query, source: fixedSource },
            })
          } else {
            next() // 'source' is already correct, proceed
          }
        } else {
          next() // 'source' is fine, proceed normally
        }
      } catch (e) {
        next()
      }
    },
  },
  {
    path: '/leave-survey',
    name: 'leave-survey-lookup',
    component: LeaveSurveyLocationLookupPage,
    meta: { templates: TEMPLATE_ENUMS.SURVEY },
  },
  {
    path: '/:companyId/leave-survey/:customerId?',
    name: 'company-leave-survey',
    component: LeaveSurveyPage,
    meta: { templates: TEMPLATE_ENUMS.SURVEY },
  },
  {
    path: '/leave-review/:locationId/:customerId?',
    name: 'leave-review',
    component: LeaveReviewPage,
    meta: { templates: TEMPLATE_ENUMS.SURVEY },
  },
  {
    path: '/leave-feedback/:surveyId/:rating?',
    name: 'leave-feedback',
    component: LeaveFeedbackPage,
    meta: { templates: TEMPLATE_ENUMS.SURVEY },
  },
  {
    path: '/qr-code/:locationId',
    name: 'qr-code',
    component: QRPage,
  },
  {
    path: '/:companyId/leave-feedback/:customerId/:rating?',
    name: 'leave-feedback-company',
    component: LeaveFeedbackPage,
    meta: { templates: TEMPLATE_ENUMS.SURVEY },
  },
  {
    path: '/giveaway/:locationId/:customerId?',
    name: 'giveaway',
    component: GiveawayPage,
    meta: { templates: TEMPLATE_ENUMS.SURVEY },
  },
  {
    path: '/sharefeedback/:locationId/:customerId?',
    name: 'sharefeedback',
    component: ShareFeedbackPage,
    meta: { templates: TEMPLATE_ENUMS.SURVEY },
  },
  {
    path: '/entered/:locationId?',
    name: 'entered',
    component: EnteredPage,
    meta: { templates: TEMPLATE_ENUMS.SURVEY },
  },
  {
    path: '/redeem-offer/:locationId/:customerId/:givenPromoId',
    name: 'redeem',
    component: RedeemPage,
    meta: { templates: TEMPLATE_ENUMS.REDEEM },
  },
  {
    path: '/:companyId/redeem-offer/:customerId/:givenPromoId',
    name: 'company-redeem',
    component: RedeemPage,
    meta: { templates: TEMPLATE_ENUMS.REDEEM },
  },
  // To support the campaigns that are added before this implementation, keeping older URLs as well.
  {
    path: '/redeem-offer/:locationId/:customerId/:givenPromoId/:campaignId',
    name: 'redeem-with-campaignId',
    component: RedeemPage,
    meta: { templates: TEMPLATE_ENUMS.REDEEM },
  },
  {
    path: '/:companyId/redeem-offer/:customerId/:givenPromoId/:campaignId',
    name: 'company-redeem-with-campaignId',
    component: RedeemPage,
    meta: { templates: TEMPLATE_ENUMS.REDEEM },
  },
  // Backwards compatible route for offers sent before 8/11/20
  {
    path: '/redeem/:locationId/:customerId/:promoId',
    name: 'deprecated-redeem',
    component: DeprecatedRedeemPage,
  },
  {
    path: '/loyalty/:locationId',
    name: 'loyalty',
    component: LoyaltyPage,
  },
  {
    path: '/rewards/:locationId/:customerId',
    name: 'rewards',
    component: RewardsPage,
    meta: {
      isFullscreen: true,
    },
  },
  {
    path: '/rewards/redeem',
    name: 'redeem-reward',
    component: RedeemRewardPage,
  },
  {
    path: '/order/locations/:companyId/:customerId?',
    name: 'order-locations',
    component: OrderLocationsPage,
  },
  {
    path: '/order/:locationId/:customerId?',
    name: 'order',
    component: OrderPage,
  },
  {
    path: '/menu/locations/:companyId/:customerId?',
    name: 'menu-locations',
    component: MenuLocationsPage,
  },
  {
    path: '/menu/:locationId/:customerId?',
    name: 'menu',
    component: MenuPage,
  },
  {
    path: '/opt-in/c/:companyId',
    name: 'opt-in-company',
    component: FauxInfoForm,
  },
  {
    path: '/opt-in/l/:locationId',
    name: 'opt-in-location',
    component: FauxInfoForm,
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
