<template>
  <div class="feedback-alert">
    <div
      role="alert"
      class="alert alert-primary"
      style="margin-bottom: 50px"
    >
      <i class="fe fe-send mr-1"></i> 
      <strong>{{ $t('entered.thank_you') }}</strong>
      {{ $t('entered.feedback_sent_confirmation') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeedbackAlert',
}
</script>

<style scoped lang="scss">
.feedback-alert {
  max-width: 300px;
  margin: auto;
}
</style>
