<template>
	<canvas id="confetti" class="card-img-top"></canvas>
</template>

<script>
import ConfettiGenerator from 'confetti-js'

export default {
  name: 'Confetti',
  mounted() {
    const confettiSettings = { target: 'confetti' }
    const confetti = new ConfettiGenerator(confettiSettings)
    confetti.render()
  }
}
</script>