<template>
  <MainContent>
    <BrandingHeader />
    <LeaveReview />
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import BrandingHeader from '@/components/Modules/Branding/BrandingHeader'
import LeaveReview from '@/components/Modules/Locations/LeaveReview'

export default {
  name: 'LeaveReviewPage',
  components: {
    MainContent,
    LeaveReview,
    BrandingHeader,
  },
}
</script>

<style scoped lang="scss"></style>
