<template>
  <MainContent>
    <BrandingHeader v-if="showBrandingHeader"/>
    <EnterGiveaway v-if="showEnterGiveaway"/>
  </MainContent>
</template>

<script>
import { mapGetters } from 'vuex'
import MainContent from '@/components/MainContent/MainContentV2'
import BrandingHeader from '@/components/Modules/Branding/BrandingHeaderV2'
import EnterGiveaway from '@/components/Modules/Customer/EnterGiveaway'

export default {
  name: 'CustomerPage',
  components: {
    MainContent,
    BrandingHeader,
    EnterGiveaway
  },
  computed: {
    ...mapGetters(['selectComponentConfig']),
    showBrandingHeader() {
      return this.selectComponentConfig?.customer?.BrandingHeader?.show === true
    },
    showEnterGiveaway() {
      return this.selectComponentConfig?.customer?.EnterGiveaway?.show === true
    }
  }
}
</script>