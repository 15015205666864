import Vue from 'vue'

export default {
  get(companyId) {
    return Vue.http.get(`company/${companyId}`)
  },
  customOptions({ companyId, source }) {
    return Vue.http.get(`company/custom-options/${companyId}/${source || 'default'}`)
  },
}
