<template>
  <div class="voucher mb-2">
    <div
      role="alert"
      v-if="leftFeedback"
      class="alert alert-primary"
      style="margin-bottom: 50px"
    >
      <i class="fe fe-send mr-1"></i>
      <strong>{{ $t('entered.thank_you') }}</strong>
      {{ $t('entered.feedback_sent_confirmation') }}
    </div>
    <div
      class="card"
      v-if="!selectCustomOptions.hideIncentiveConfirmation && !selectIsBlocked"
    >
      <div class="card-body text-center">
        <a href="#" class="avatar avatar-md card-avatar card-avatar-top">
          <i
            :class="[
              'fas',
              incentive.type === 'sweepstakes' ? 'fa-dice' : 'fa-gift',
            ]"
            style="font-size: 3rem"
          ></i>
        </a>

        <h2 class="card-header-title">
          {{ incentiveConfirmation }}
          <span class="text-primary">{{ incentive.prize }}.</span>
        </h2>
      </div>

      <div v-if="incentive.type === 'sweepstakes'" class="card-footer">
        <small>{{ $t('entered.sweepstakes_winner_notice') }}</small>
      </div>
    </div>
    <div
      class="card"
      v-if="!selectCustomOptions.hideReviewPlatforms && !selectIsBlocked"
    >
      <div class="card-body text-center">
        <h4 class="card-title text-center text-primary mt-1 mb-1">
          {{ $t('entered.platforms_list.title') }}
        </h4>
      </div>
      <div class="card-footer">
        <div
          role="navigation"
          aria-label="platforms"
          class="row align-items-center"
        >
          <div v-for="platform in platforms" :key="platform._id" class="col-12">
            <button
              @click="review(platform)"
              class="btn btn-block btn-white mb-3 text-left brand"
            >
              <img
                :src="platform.img"
                :alt="`${platform.name} logo`"
                class="avatar-img avatar-xxs rounded mr-2"
              />
              <span>{{ platform.name }}</span>
            </button>
          </div>
          <hr class="separator" v-if="showSeparator" />
          <div
            v-for="cta in callToActions"
            :key="cta._id"
            class="mx-3 w-100 mb-3"
          >
            <button
              @click="visitCTA(cta)"
              class="btn btn-block btn-white d-flex align-items-center"
            >
              <img
                v-if="cta.logo"
                :src="cta.logo"
                :alt="`${cta.action} logo`"
                class="avatar-img avatar-xxs rounded mr-3"
              />
              <div class="text-left" style="white-space: normal">
                {{ cta.action }}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, createNamespacedHelpers } from 'vuex'
import { getPlatformDetails } from '@/lib/platforms'

const CustomerModule = createNamespacedHelpers('CustomerModule')

export default {
  name: 'Entered',
  computed: {
    ...mapState(['location']),
    ...mapGetters(['incentive', 'callToActions', 'selectCustomOptions']),
    ...CustomerModule.mapGetters(['selectIsBlocked']),
    platforms() {
      return (
        this.location?.subscriptions
          ?.filter((p) => !p.hidden)
          ?.map((platform) => getPlatformDetails(platform))
          ?.sort((a, b) => a.displayOrder - b.displayOrder) ?? []
      )
    },
    leftFeedback() {
      return this.$route.query.prev === 'feedback'
    },
    incentiveConfirmation() {
      switch (this.incentive.type) {
        case 'instant':
          return this.$t('entered.incentive_confirmation.instant')
        case 'sweepstakes':
        default:
          return this.$t('entered.incentive_confirmation.default')
      }
    },
    showSeparator() {
      return this.callToActions.length && this.platforms.length
    },
  },
  methods: {
    review(platform) {
      this.$mixpanel.track('Visiting the platform', {
        ...this.mixpanelPayload,
        platform: platform.name,
        url: platform.pageUrl,
      })
      window.open(platform.pageUrl, '_blank')
    },
    visitCTA(cta) {
      this.$mixpanel.track('Visiting Call To Action', {
        ...this.mixpanelPayload,
        action: cta.action,
        url: cta.url,
      })
      window.open(cta.url, '_blank')
    },
  },
  async mounted() {
    this.$mixpanel.track('Entered to Win Confirmation')
  },
}
</script>

<style scoped lang="scss">
.voucher {
  max-width: 300px;
  margin: auto;
}
.separator {
  width: 100%;
  margin-top: 4px;
}
</style>
