<template>
  <div>
    <div class="card-header text-left">
      <h1 class="text-center mt-4 text-primary text-capitalize">
        {{ givenPromo.promo.title }}
      </h1>
    </div>
    <div class="text-center card-body" v-if="givenPromo.promo.description">
      <div>
        <p class="mb-0">{{ givenPromo.promo.description }}</p>
      </div>
    </div>
    <div class="card-footer">
      <button
        v-if="!isRedeemable"
        disabled
        class="btn btn-secondary btn-lg btn-block disabled mb-3 mt-3"
      >
        {{ $t('redemption_redeemed.already_redeemed') }}
      </button>
      <small v-else class="text-muted">
        {{ footer }}
      </small>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  // TODO: Select in store or off premise page
  name: 'RedemptionRedeemed',
  props: ['givenPromo'],
  computed: {
    ...mapGetters(['isGivenPromoRedeemable']),
    isRedeemable() {
      const { _id: id = null } = this.givenPromo || {}
      return this.isGivenPromoRedeemable(id)
    },
    footer() {
      return this.givenPromo.redemptionSpot === 'in-store'
        ? this.$t('redemption_redeemed.footer.in_store')
        : this.$t('redemption_redeemed.footer.online')
    },
  },
}
</script>
