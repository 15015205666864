<template>
  <MainContent>
    <BrandingHeader />
    <QuestionSection />
    <QuestionProgressBar v-if="!previouslyCompleted" />
  </MainContent>
</template>

<script>
import { mapState } from 'vuex'
import MainContent from '@/components/MainContent/MainContent'
import BrandingHeader from '@/components/Modules/Branding/BrandingHeader'
import QuestionSection from '@/components/Modules/CustomQuestions/QuestionSection'
import QuestionProgressBar from '@/components/Modules/CustomQuestions/QuestionProgressBar'

export default {
  name: 'CustomQuestionsPage',
  components: {
    MainContent,
    BrandingHeader,
    QuestionSection,
    QuestionProgressBar,
  },
  computed: {
    ...mapState(['previouslyCompleted']),
  },
}
</script>
