import Vue from 'vue'

export default {
  getQuestionList({ listId }) {
    return Vue.http.get(`custom-question-list/${listId}`)
  },
  saveAnswer({ answerFields }) {
    return Vue.http.post('custom-question-answer', { answerFields })
  },
  deleteAnswer({ questionListId, questionId }) {
    return Vue.http.post('custom-question-answer/delete', {
      questionListId,
      questionId,
    })
  },
  markCompleted({ questionListId, companyId, locationId, customer }) {
    return Vue.http.post('custom-question-list/mark-completed', {
      questionListId,
      companyId,
      locationId,
      customer,
    })
  },
  getCustomQuestionSettings({ companyId }) {
    return Vue.http.get(`custom-question-setting/${companyId}`)
  },
  updateCustomQuestionList({ questionListId, newQuestionList }) {
    return Vue.http.put('custom-question-list/update', {
      questionListId,
      newQuestionList,
    })
  },
}
