<template>
  <div class="loyalty">
    <FadeTransition>
      <AddPhone v-if="state === 'add-phone'" @added="addedPhone" />
      <AddCard v-if="state === 'add-card'" />
    </FadeTransition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FadeTransition from '@/components/Transitions/FadeTransition'
import AddPhone from '@/components/Modules/Loyalty/AddPhone'
import AddCard from '@/components/Modules/Loyalty/AddCard'

export default {
  name: 'Loyalty',
  components: {
    FadeTransition,
    AddCard,
    AddPhone,
  },
  data: () => ({
    state: 'add-phone',
  }),
  computed: {
    ...mapState(['customer', 'location']),
  },
  methods: {
    addedPhone() {
      if (!this.customer.cardCount) {
        this.state = 'add-card'
      } else {
        this.$router.push({
          name: 'rewards',
          params: {
            locationId: this.location._id,
            customerId: this.customer._id,
          },
        })
      }
    },
  },
  async mounted() {
    this.$mixpanel.track('Loyalty', {
      ...this.mixpanelPayload,
    })
  },
}
</script>

<style scoped lang="scss">
.loyalty {
  max-width: 320px;
  margin: auto;
}
</style>
