<template>
  <div
    class="container px-0"
    :style="containerStyles"
    :class="{ fading: transitioningLoyalty }"
  >
    <div class="row justify-content-center mx-0">
      <div class="rewards col-12 col-md-7 col-lg-6 col-xl-6 px-0">
        <div v-if="initialized" class="card" :style="cardStyles">
          <RewardsCover />
          <RewardsTabs v-if="isIntegrated" @selected="tabSelected" />
          <RewardsOverview v-if="tab === 'overview'" />
          <RewardsAccount v-if="tab === 'account'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import RewardsCover from './RewardsCover'
import RewardsTabs from './RewardsTabs'
import RewardsOverview from './RewardsOverview'
import RewardsAccount from './RewardsAccount'

export default {
  name: 'Rewards',
  components: {
    RewardsCover,
    RewardsTabs,
    RewardsOverview,
    RewardsAccount,
  },
  data: () => ({
    initialized: false,
    tab: 'overview',
  }),
  computed: {
    ...mapState('loyalty', ['transitioningLoyalty']),
    ...mapGetters('loyalty', ['isIntegrated']),
    ...mapState(['customer', 'location']),
    containerStyles() {
      return {
        marginTop: this.$mq === 'sm' ? '0px' : '30px',
      }
    },
    cardStyles() {
      if (this.$mq === 'sm') {
        return {
          border: 'none',
          borderRadius: '0px',
        }
      }
      return {}
    },
  },
  methods: {
    ...mapActions('loyalty', ['getLoyalty']),
    tabSelected(tab) {
      this.tab = tab
    },
  },
  async mounted() {
    // TODO: Mixpanel
    await this.getLoyalty({
      customerId: this.customer._id,
      companyId: this.location.company._id,
    })
    this.initialized = true
  },
}
</script>

<style scoped lang="scss">
.container {
  transition: opacity 100ms linear;
  &.fading {
    opacity: 0;
  }
}
</style>
