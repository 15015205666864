<template>
  <div>
    <div class="card-header text-left">
      <h1 class="text-center mt-4 text-primary text-capitalize">
        {{ givenPromo.promo.title }}
      </h1>
    </div>
    <div class="text-center card-body">
      <div v-if="givenPromo.promo.description">
        <p class="mb-0">{{ givenPromo.promo.description }}</p>
      </div>
      <div class="mt-2" v-if="givenPromo.promo.termsAndConditions">
        <a
          class="mb-0 text-primary"
          :href="givenPromo.promo.termsAndConditions"
          target="_blank"
          ><u>{{ $t('redemption_start.additional_terms') }}</u>
        </a>
      </div>
    </div>
    <div class="card-footer">
      <button
        v-if="isExpired"
        disabled
        class="btn btn-secondary btn-lg btn-block mb-3 mt-3"
      >
        {{
          $t('redemption_start.expired', [
            $moment(givenPromo.promo.expirationDate).format('M/D/YY'),
          ])
        }}
      </button>
      <button
        v-else
        @click="$emit('redeem')"
        class="btn btn-primary btn-lg btn-block mb-3 mt-3"
      >
        {{ $t('redemption_start.button.submit') }}
      </button>
    </div>
    <div class="card-footer" v-if="!isRedeemed && !isExpired">
      <small class="text-muted">{{ $t('redemption_start.limit') }}</small>
      <small v-if="givenPromo.promo.expirationDate" class="text-muted">
        <br />
        {{
          $t('redemption_start.expiration', [
            $moment(givenPromo.promo.expirationDate).format('M/D/YY'),
          ])
        }}
      </small>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'RedemptionStart',
  props: ['givenPromo', 'isExpired', 'isRedeemed'],
  mounted() {
    this.$mixpanel.track('RedemptionStart', {
      ...this.selectMixPanelPayload,
      customer: this.selectCustomer?._id,
    })
  },
  computed: {
    ...mapGetters(['selectMixPanelPayload', 'selectCustomer']),
  },
}
</script>
