<template>
  <div class="card shadow text-center py-5">
    <h1 class="card-body mb-0 font-weight-bold">
      This Question List is already completed.
    </h1>
    <h2 class="card-body px-5 mb-0">
      Thank you! Your feedback is very important to us.
    </h2>
  </div>
</template>

<script>
export default {
  name: 'PreviouslyCompletedCard',
  mounted() {
    this.$emit('celebrate')
  },
}
</script>
