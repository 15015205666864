<template>
  <countdown :time="time" @end="$emit('end')">
    <template slot-scope="props"
      >{{ props.minutes }}:{{ convertToSeconds(props.seconds) }}</template
    >
  </countdown>
</template>

<script>
export default {
  name: 'CountdownTimer',
  props: ['time'],
  methods: {
    convertToSeconds(seconds) {
      if (seconds < 10) {
        return `0${seconds}`
      }
      return seconds
    },
  },
}
</script>
