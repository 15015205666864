import Vue from 'vue'

export default {
  addCard(payload) {
    return Vue.http.post('loyalty/add-card', payload)
  },
  getLoyalty(payload) {
    return Vue.http.post('loyalty/get-loyalty', payload)
  },
  redeemReward(payload) {
    return Vue.http.post('loyalty/redeem-reward', payload)
  },
}
