<template>
  <div>
    <div class="card-header text-left">
      <h1 class="text-center mt-4 text-primary text-capitalize">
        {{ $t('redemption_confirm.verify') }}
      </h1>
    </div>
    <div class="text-center card-body">
      <div>
        <i18n
          path="redemption_confirm.limit_warning"
          tag="label"
          for="'redemption_confirm.time_limit'"
        >
          <strong class="text-primary">
            {{ $t('redemption_confirm.time_limit') }}
          </strong>
        </i18n>
      </div>
    </div>
    <div class="card-footer">
      <button
        @click="$emit('confirmed')"
        class="btn btn-primary btn-lg btn-block mb-3 mt-3"
      >
        {{ $t('redemption_confirm.button.submit') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'RedemptionConfirm',
  mounted() {
    this.$mixpanel.track('RedemptionConfirm', {
      ...this.selectMixPanelPayload,
      customer: this.selectCustomer?._id,
    })
  },
  computed: {
    ...mapGetters(['selectMixPanelPayload', 'selectCustomer']),
  },
}
</script>
