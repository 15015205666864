<template>
  <MainContent>
    <BrandingHeader />
    <OrderLocations />
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import BrandingHeader from '@/components/Modules/Branding/BrandingHeader'
import OrderLocations from '@/components/Modules/Order/OrderLocations'

export default {
  name: 'OrderLocationsPage',
  components: {
    MainContent,
    OrderLocations,
    BrandingHeader,
  },
}
</script>

<style scoped lang="scss"></style>
