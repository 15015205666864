<template>
  <div class="row align-items-center">
    <div class="col-auto pr-0">
      <img src="@/assets/loyalty/reward-icon.png" width="40px" />
    </div>
    <div class="col">
      <h4 class="mb-1 name">{{ reward.title }}</h4>
      <p class="small mb-0 text-capitalize">{{ costTitle(reward.cost) }}</p>
    </div>
    <div v-if="canRedeem" class="col text-right">
      <button @click="redeem(reward)" class="btn btn-sm btn-primary">
        Redeem
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'UpcomingRewardItem',
  props: ['reward'],
  computed: {
    ...mapGetters('loyalty', ['costTitle', 'availablePoints']),
    canRedeem() {
      return this.availablePoints >= this.reward.cost
    },
  },
  methods: {
    ...mapActions('loyalty', ['startRewardRedemption']),
    async redeem(reward) {
      await this.startRewardRedemption(reward)
      this.$router.push({ name: 'redeem-reward' })
    },
  },
}
</script>

<style scoped lang="scss"></style>
