<template>
  <div class="branding-header" :class="{ centered, light }">
    <div
      class="avatar avatar-xl mb-4"
      :class="{ 'vp-xl': !showCompanyInfo }"
      v-if="logo && logo.indexOf('assets.ovationup.com') === -1"
    >
      <img :src="logo" alt="company logo" class="avatar-img rounded-circle" />
    </div>
    <h3 class="text-primary text-center mb-5" v-if="showCompanyInfo">
      {{ companyName }}
      <p class="text-muted mt-3" v-if="showLocationName && hasLocation">
        {{ locationName }}
      </p>
    </h3>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BrandingHeader',
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    showLocationName: {
      type: Boolean,
      default: false,
    },
    showCompanyInfo: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    ...mapState(['location', 'company']),
    hasLocation() {
      return !!this.location && this.location._id
    },
    branding() {
      return this.hasLocation ? this.location : this.company
    },
    companyName() {
      return this.hasLocation ? this.location.company.name : this.company.name
    },
    locationName() {
      return this.hasLocation
        ? this.location.friendlyName || this.location.city
        : ''
    },
    settings() {
      return (
        (this.hasLocation
          ? this.location.company.settings
          : this.company.settings) || {}
      )
    },
    logo() {
      return this.settings.logo
    },
  },
}
</script>

<style scoped lang="scss">
.centered {
  h3 {
    margin-bottom: 0px !important;
  }
}

.light {
  h3 {
    color: #fff !important;
  }
}

.vp-xl {
  height: calc(82px + 3.5vw);
  width: calc(82px + 3.5vw);
}
</style>
