<template>
  <div class="row align-items-center">
    <div class="col-auto activity-date text-center">
      <h2 class="text-muted">{{ activity.createdAt | moment('MMM') }}</h2>
      <h3 class="text-muted">{{ activity.createdAt | moment('D') }}</h3>
    </div>
    <div class="col">
      <h4 class="mb-1 name">{{ title }}</h4>
      <p class="small mb-0 text-capitalize" :class="[pointClass]">
        {{ pointLabel }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LoyaltyActivityItem',
  props: ['activity'],
  computed: {
    ...mapState(['location']),
    title() {
      if (this.activity.source === 'checkin')
        return `Visited ${this.location.company.name}`
      if (this.activity.source === 'survey') return `Took a Survey`
      if (this.activity.title) return this.activity.title
      return this.activity.rewardTitle
    },
    pointClass() {
      return this.activity.points > 0 ? 'text-success' : 'text-danger'
    },
    pointLabel() {
      const symbol = this.activity.points > 0 ? '+' : ''
      const suffix =
        this.activity.points === 1 || this.activity.points === -1 ? '' : 's'
      return `${symbol}${this.activity.points} point${suffix}`
    },
  },
}
</script>

<style scoped lang="scss">
.activity-date {
  h2 {
    font-size: 12px;
    text-transform: uppercase;
    margin: 0;
    letter-spacing: 1px;
  }
  h3 {
    font-size: 18px;
    text-transform: uppercase;
    margin: 0;
    letter-spacing: 1px;
  }
}
</style>
