<template>
  <div class="add-card">
    <div class="card-container">
      <img src="@/assets/loyalty/credit-card.png" class="card-img" />
      <img src="@/assets/loyalty/last-four.png" class="last-four-img" />
    </div>

    <h1 class="text-center mb-4 question-title">
      Automatically get points for your purchases
    </h1>

    <form @submit.prevent="submitCard">
      <div class="row">
        <div class="text-left form-group col-12 inline-block">
          <label>Last four of card</label>
          <input
            v-model="lastFour"
            type="tel"
            class="form-control"
            placeholder="1234"
            autocomplete="tel"
            :disabled="isLoading"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <button
            type="submit"
            class="btn btn-lg btn-block btn-primary mb-3"
            :class="{ 'is-loading': isLoading }"
            :disabled="shouldNotContinue"
          >
            Continue
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'AddCard',
  data: () => ({
    lastFour: '',
    isLoading: false,
  }),
  computed: {
    ...mapState(['location', 'customer']),
    shouldNotContinue() {
      return !this.lastFour || this.lastFour.length !== 4 || this.isLoading
    },
  },
  methods: {
    ...mapActions('loyalty', ['addCard']),
    async submitCard() {
      this.isLoading = true

      console.log('GO:', {
        customerId: this.customer._id,
        companyId: this.location.company._id,
        lastFour: this.lastFour,
      })

      await this.addCard({
        customerId: this.customer._id,
        companyId: this.location.company._id,
        lastFour: this.lastFour,
      })

      this.$router.push({
        name: 'rewards',
        params: {
          locationId: this.location._id,
          customerId: this.customer._id,
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.card-container {
  position: relative;
  margin-bottom: 10px;

  .card-img {
    width: 150px;
  }

  .last-four-img {
    position: absolute;
    top: 38px;
    right: 22px;
    width: 80px;
  }
}
</style>
