import CompanyService from '../services/CompanyService'
import Theme from '../lib/theme'

export default {
  namespaced: true,

  state: {
    company: {}
  },
  getters: {
    selectCompany: (state) => state.company,
    selectIncentive: (state, getters, rootState, rootGetters) => {
        if (!state.company._id) return {}

        const { defaultIncentive = {}, incentives = [] } = state.company
        const sourceNames = rootGetters.selectSources.map(source => (source.name || '').replace(/-/g, '_').toLowerCase())
        const language = rootGetters['i18n/selectLoadedLanguage']

        for (var sourceName of sourceNames) {
            const matchedIncentive = incentives.find((i) =>
                i.sources.some((s) => [
                    sourceName,
                    sourceName.replace(/_/g, ' ')
                ].includes(s.toLowerCase())) && i.language === language
            )
            if (matchedIncentive) return matchedIncentive

            const fallbackMatchedIncentive = incentives.find((i) =>
                i.sources.some((s) => [
                    sourceName,
                    sourceName.replace(/_/g, ' ')
                ].includes(s.toLowerCase())) && (!i.language || i.language === 'en')
            )
            if (fallbackMatchedIncentive) return fallbackMatchedIncentive
        }

        return defaultIncentive
    }
  },
  mutations: {
    SET_COMPANY(state, company) {
      state.company = company || {}
      
      if (company && company.settings.color) {
        Theme.applyColor(company.settings.color)
      }
    },
  },
  actions: {
    setCompany({ commit }, company) {
        commit('SET_COMPANY', company)
    }
  },
}
