<template>
  <div class="order">
    <div class="card">
      <div class="card-body text-left">
        <h3 class="card-title text-center mt-3 mb-0">
          {{ $t('pick_survey_location.title') }}
        </h3>
        <div
          v-if="locations.length > 5"
          class="text-left form-group inline-block mt-3 mb--3"
        >
          <input
            v-model="filterText"
            type="text"
            class="form-control"
            :placeholder="$t('pick_survey_location.search.placeholder')"
            autocomplete="off"
          />
        </div>
      </div>

      <div class="card-footer">
        <div class="row align-items-center">
          <div
            v-for="location in filteredLocations"
            :key="location._id"
            class="col-12"
          >
            <button
              @click="pickLocation(location)"
              class="btn btn-lg btn-block btn-white mb-3 text-left brand"
              style="white-space: wrap"
            >
              <span>{{
                location.displayName || location.friendlyName || location.city
              }}</span>
              <div class="small text-muted">
                {{ location.friendlyAddress }}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import _ from 'lodash'

export default {
  name: 'PickSurveyLocation',
  data() {
    return {
      filterText: '',
    }
  },
  computed: {
    ...mapState(['company', 'source', 'mixpanelPayload']),
    filteredLocations() {
      if (!this.filterText) return this.locations
      return (this.locations || []).filter((l) =>
        [l.friendlyAddress, l.friendlyName, l.city, l.displayName].some((f) =>
          (f || '')
            .toLowerCase()
            .includes((this.filterText || '').toLowerCase()),
        ),
      )
    },
    locations() {
      return _.sortBy(
        (this.company.locations || []).map((location) => ({
          ...location,
          friendlyAddress:
            `${location.addressDetails.streetNumber} ${location.addressDetails.streetName}` +
            (location.friendlyName ? `, ${location.addressDetails.city}` : ''),
        })),
        (location) =>
          location.displayName || location.friendlyName || location.city,
      ).filter((l) => l.status !== 'cancelled')
    },
  },
  methods: {
    ...mapActions(['getLocation']),
    async pickLocation(location) {
      const { customerId } = this.$route.params
      // 'redirected' logic was added for lehiBakery because they only got qr-codes for one location
      const { language, redirected } = this.$route.query

      this.$router.push({
        name: 'leave-survey',
        params: {
          customerId,
          locationId: location._id,
        },
        query: {
          source: this.source,
          language,
          ...(redirected ? { redirected } : {}),
        },
      })

      await this.getLocation(location._id)
    },
  },
  mounted() {
    this.$mixpanel.track('Order Locations Prompt', {
      ...this.mixpanelPayload,
    })
  },
}
</script>

<style scoped lang="scss">
.order {
  max-width: 300px;
  margin: auto;
}
</style>
