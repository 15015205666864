<template>
  <div class="order">
    <div class="card">
      <div class="card-body text-left">
        <!-- <a href="#" class="avatar avatar-md card-avatar card-avatar-top">
          <img src="/theme/img/ratings/5.png" class="avatar-img rounded-circle" alt="..." />
        </a> -->
        <h2 class="card-title text-center text-primary mt-2">Order Ahead</h2>
        <h3 class="card-title text-center mt-3 mb-0">Choose a location</h3>

        <div
          v-if="orderLocations.length > 5"
          class="text-left form-group inline-block mt-3 mb--3"
        >
          <input
            v-model="filterText"
            type="text"
            class="form-control"
            placeholder="Search"
            autocomplete="off"
          />
        </div>
      </div>

      <div class="card-footer">
        <div class="row align-items-center">
          <div
            v-for="location in filteredLocations"
            :key="location._id"
            class="col-12"
          >
            <button
              @click="showLocation(location)"
              class="btn btn-lg btn-block btn-white mb-3 text-left brand"
            >
              <span>{{ location.friendlyName || location.city }}</span>
              <!-- <div>{{ location.city }}</div> -->
              <div class="small text-muted">{{ location.friendlyAddress }}</div>
              <!-- <span>{{ location.city }}</span> -->
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  name: 'OrderLocations',
  data() {
    return {
      filterText: '',
    }
  },
  computed: {
    ...mapState(['company', 'customerId', 'mixpanelPayload']),
    filteredLocations() {
      if (!this.filterText) return this.orderLocations
      return (this.orderLocations || []).filter((l) =>
        [l.friendlyAddress, l.friendlyName, l.city].some((f) =>
          (f || '')
            .toLowerCase()
            .includes((this.filterText || '').toLowerCase()),
        ),
      )
    },
    orderLocations() {
      return _.sortBy(
        (this.company.locations || [])
          .filter(({ orderPlatforms }) => {
            return orderPlatforms && orderPlatforms.length
          })
          .map((location) => ({
            ...location,
            friendlyAddress:
              `${location.addressDetails.streetNumber} ${location.addressDetails.streetName}` +
              (location.friendlyName
                ? `, ${location.addressDetails.city}`
                : ''),
          })),
        (location) => location.friendlyName || location.city,
      )
    },
  },
  methods: {
    showLocation(location) {
      this.$router.push({
        name: 'order',
        params: {
          companyId: this.company._id,
          locationId: location._id,
          customerId: this.customerId,
        },
      })
    },
  },
  mounted() {
    this.$mixpanel.track('Order Locations Prompt', {
      ...this.mixpanelPayload,
    })
  },
}
</script>

<style scoped lang="scss">
.order {
  max-width: 300px;
  margin: auto;
}
</style>
