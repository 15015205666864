<template>
  <div>
    <div v-for="(option, index) of questionOptions" :key="index">
      <button
        class="btn btn-block text-left pl-4 mb-2"
        :class="{
          'btn-primary': selectedIndex === index,
          'btn-outline-secondary': selectedIndex !== index,
        }"
        style="white-space: normal"
        @click="setSelected(index)"
      >
        {{ option }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'MultipleSelection',
  data: () => ({
    selectedIndex: null,
  }),
  computed: {
    ...mapState([
      'customQuestionList',
      'currentQuestionIndex',
      'customQuestionAnswers',
    ]),
    questionOptions() {
      return this.customQuestionList[this.currentQuestionIndex].questionData
        .options
    },
  },
  methods: {
    ...mapMutations(['storeAnswer', 'setQuestionStartTime']),
    setSelected(index) {
      this.selectedIndex = index
      this.storeAnswer(this.selectedIndex)
      this.$emit('validate')
    },
  },
  mounted() {
    this.setQuestionStartTime()
    this.selectedIndex = this.customQuestionAnswers[this.currentQuestionIndex]
  },
}
</script>

<style scoped>
.selected {
  background-color: var(--primary) !important;
  color: white !important;
}
.btn.btn-outline-secondary {
  color: black !important;
}
.btn-outline-secondary:hover {
  background-color: white;
}
.btn:focus,
.btn:active {
  outline: none;
  box-shadow: none;
}
</style>
