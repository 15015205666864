<template>
  <div>
    <canvas id="confetti" class="card-img-top"></canvas>
    <PreviouslyCompletedCard
      v-if="previouslyCompleted"
      @celebrate="celebrate()"
    />
    <div v-else>
      <FadeTransition>
        <div
          class="card shadow"
          style="min-height: 300px"
          :key="currentQuestionIndex"
        >
          <!-- question -->
          <div
            class="px-4 pt-4 border-bottom pb-0 text-left"
            v-if="!finishedAllQuestions"
          >
            <div class="card-title pb-3 h4">
              {{ customQuestionList[currentQuestionIndex].question }}
            </div>
            <div
              class="card-subtitle text-muted h5"
              v-if="questionType === 'SINGLE_SELECTION'"
            >
              Select one
            </div>
            <div
              class="card-subtitle text-muted h5"
              v-if="questionType === 'MULTIPLE_SELECTION'"
            >
              Select all that apply
            </div>
          </div>

          <!-- type specific input -->
          <div class="card-body px-0">
            <!-- no padding so that emoji's fit on 320px screens -->
            <div v-if="!finishedAllQuestions">
              <RatingScale
                v-if="questionType === 'RATING_SCALE'"
                @validate="validateInput"
              />
              <div class="px-4">
                <ShortAnswer
                  v-if="questionType === 'SHORT_ANSWER'"
                  @validate="validateInput"
                />
                <SingleSelection
                  v-if="questionType === 'SINGLE_SELECTION'"
                  @validate="validateInput"
                />
                <MultipleSelection
                  v-if="questionType === 'MULTIPLE_SELECTION'"
                  @validate="validateInput"
                />
              </div>
            </div>

            <!-- 'thank you' section, show only after all questions are answered-->
            <div class="py-5" v-else>
              <h1 class="card-body mb-0 font-weight-bold">
                Thank you
                <span class="text-capitalize" v-if="customer.name">
                  {{ customer.name.split(' ')[0] }}!
                </span>
                <span class="ml--2" v-else>!</span>
              </h1>
              <h2 class="card-body px-5 mb-0">
                {{ thankYouWording }}
              </h2>
            </div>
          </div>

          <!-- footer controls -->
          <div class="card-footer" v-if="!finishedAllQuestions">
            <button
              class="btn btn-outline-secondary float-left"
              @click="previous"
              v-if="currentQuestionIndex !== 0"
            >
              <i class="fas fa-arrow-left"></i>
            </button>
            <button
              class="btn btn-primary float-right"
              @click="next"
              :disabled="!validAnswer"
              v-if="!isLastQuestion"
            >
              Next
            </button>
            <button
              class="btn btn-primary float-right"
              @click="finish"
              :disabled="!validAnswer"
              v-if="isLastQuestion"
            >
              Submit
            </button>
          </div>
        </div>
      </FadeTransition>
    </div>
  </div>
</template>

<script>
import ConfettiGenerator from 'confetti-js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import PreviouslyCompletedCard from './PreviouslyCompletedCard'
import ShortAnswer from './QuestionTypes/ShortAnswer'
import RatingScale from './QuestionTypes/RatingScale'
import SingleSelection from './QuestionTypes/SingleSelection'
import MultipleSelection from './QuestionTypes/MultipleSelection'
import FadeTransition from '@/components/Transitions/FadeTransition'

export default {
  name: 'QuestionSection',
  components: {
    ShortAnswer,
    RatingScale,
    SingleSelection,
    MultipleSelection,
    FadeTransition,
    PreviouslyCompletedCard,
  },
  data: () => ({
    validAnswer: false,
  }),
  computed: {
    ...mapState([
      'customQuestionList',
      'currentQuestionIndex',
      'customer',
      'finishedAllQuestions',
      'previouslyCompleted',
      'customQuestionAnswers',
      'isPreview',
    ]),
    ...mapGetters(['selectCustomThanks']),
    questionType() {
      return this.customQuestionList[this.currentQuestionIndex].type
    },
    isLastQuestion() {
      const answerList = this.customQuestionAnswers
      const followUpData = this.checkForFollowUp()
      if (!followUpData) {
        return false
      }
      const isLast =
        (this.currentQuestionIndex + 1 === this.customQuestionList.length &&
          !followUpData.newFollowUpQuestion) ||
        (this.currentQuestionIndex + 1 === this.customQuestionList.length - 1 &&
          answerList.length === this.customQuestionList.length &&
          !followUpData.newFollowUpQuestion &&
          followUpData.nextQuestion.isFollowUp)

      return isLast
    },
    questionKey() {
      return this.customQuestionList[this.currentQuestionIndex]._id
    },
    thankYouWording() {
      return this.selectCustomThanks || 'Your feedback is very important to us.'
    },
  },
  methods: {
    ...mapMutations(['changeCurrentIndex', 'setFinished', 'setAnswerTime']),
    ...mapActions([
      'saveAnswer',
      'markCompleted',
      'removeNextFollowUpQuestion',
      'insertFollowUpQuestion',
    ]),
    validateInput() {
      const currentAnswer =
        this.customQuestionAnswers[this.currentQuestionIndex]
      if (typeof currentAnswer == 'string') {
        this.validAnswer = currentAnswer.trim().length >= 2
      } else if (currentAnswer === 0) {
        this.validAnswer = true
      } else {
        this.validAnswer = currentAnswer
      }
    },
    async save() {
      this.setAnswerTime()
      const followUpData = this.checkForFollowUp()
      await this.processFollowUp(followUpData)
      if (!this.isPreview) {
        this.saveAnswer()
      }
      if (!this.isLastQuestion) {
        this.changeCurrentIndex(1)
      }
      this.validAnswer = false
    },
    checkForFollowUp() {
      const currentQuestion = this.customQuestionList[this.currentQuestionIndex]
      const nextQuestion =
        this.customQuestionList[this.currentQuestionIndex + 1]
      const currentAnswer =
        this.customQuestionAnswers[this.currentQuestionIndex]

      if (!currentAnswer && currentAnswer !== 0) return

      // If type is rating scale the index was previously incremented to account
      // for zero base. That way the rating reflected right in the answer. So decrement now.
      const optionIndex =
        currentQuestion.type === 'RATING_SCALE'
          ? currentAnswer - 1
          : currentAnswer

      const followUp =
        currentQuestion.children &&
        currentQuestion.children.find(
          (c) => c.optionIndex === optionIndex.toString(),
        )

      const newFollowUpQuestion = followUp && followUp.question

      return { newFollowUpQuestion, nextQuestion }
    },
    async processFollowUp({ newFollowUpQuestion, nextQuestion }) {
      if (!newFollowUpQuestion && !nextQuestion) {
        return
      }

      if (newFollowUpQuestion && (!nextQuestion || !nextQuestion.isFollowUp)) {
        // if no next question, or if next question isn't a follow up, we can insert.
        await this.insertFollowUpQuestion(newFollowUpQuestion)
      } else if (
        newFollowUpQuestion &&
        newFollowUpQuestion._id === nextQuestion._id
      ) {
        // if the next question is the same as the newFollowUpQuestion then it
        // has already been added (back button was used)
        return
      } else if (newFollowUpQuestion && nextQuestion.isFollowUp) {
        // if this statement is executed, the next question is a follow-up but not the right
        // one. (back button was used and parent answer changed causing a new follow up)
        await this.removeNextFollowUpQuestion()
        await this.insertFollowUpQuestion(newFollowUpQuestion)
      } else if (!newFollowUpQuestion && nextQuestion.isFollowUp) {
        // if this statement is executed, the next question is a follow-up but should not be
        // one. (back button was used and parent answer changed to an answer with no follow up)
        await this.removeNextFollowUpQuestion()
      }
    },
    async next() {
      await this.save()
      this.validateInput()
    },
    previous() {
      this.setAnswerTime()
      this.changeCurrentIndex(-1)
      this.validateInput()
    },
    celebrate() {
      const confettiSettings = { target: 'confetti' }
      const confetti = new ConfettiGenerator(confettiSettings)
      confetti.render()
    },
    async finish() {
      this.celebrate()
      await this.save()
      if (!this.isPreview) {
        this.markCompleted()
      }
      this.setFinished()
      this.changeCurrentIndex(1)
    },
  },
}
</script>

<style scoped>
.btn:focus,
.btn:active {
  outline: none;
  box-shadow: none;
}
</style>
