<template>
  <MainContent>
    <BrandingHeader />
    <RedeemReward />
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import BrandingHeader from '@/components/Modules/Branding/BrandingHeader'
import RedeemReward from '@/components/Modules/Loyalty/Rewards/RedeemReward'

export default {
  name: 'RedeemRewardPage',
  components: {
    MainContent,
    RedeemReward,
    BrandingHeader,
  },
}
</script>

<style scoped lang="scss"></style>
