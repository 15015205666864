import CustomerService from '../services/CustomerService'
import { i18n } from '../setup/i18n-setup'

export default {
  namespaced: true,

  state: {
    customer: {}
  },
  getters: {
    selectCustomer: (state) => state.customer,
    selectIsBlocked: (state, getters, rootState) => state.customer?.blocked?.includes(rootState.company?._id)
  },
  mutations: {
    SET_CUSTOMER(state, customer) {
      state.customer = customer || {}
    },
  },
  actions: {
    setCustomer({ commit }, customer) {
        commit('SET_CUSTOMER', customer)
    },
    async createCustomer({ dispatch }, options) {
      const loadingId = await dispatch(
        'addToLoader',
        { name: 'CREATE_CUSTOMER' },
        { root: true }
      )
      let result
      try {
        const response = await CustomerService.createCustomer(options)
        result = response.body.data
      } catch (e) {
        await dispatch(
          'addToErrors',
          { name: 'CREATE_CUSTOMER', message: i18n.global.t('common.errors.casual.default_retry') },
          { root: true }
        )
      } finally {
        await dispatch('removeFromLoader', loadingId, { root: true })
        return result
      }
    }
  },
}
