<template>
  <div class="loyalty-activiy">
    <h2 class="mb-3">Activity</h2>
    <div class="card">
      <div class="card-body">
        <div v-for="(activity, idx) in pastActivity" :key="activity._id">
          <LoyaltyActivityItem :activity="activity" />
          <hr v-if="idx < pastActivity.length - 1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoyaltyActivityItem from './LoyaltyActivityItem'

export default {
  name: 'LoyaltyActivity',
  components: {
    LoyaltyActivityItem,
  },
  computed: {
    ...mapGetters('loyalty', ['pastActivity']),
  },
}
</script>

<style scoped lang="scss"></style>
