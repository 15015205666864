<template>
  <div>
    <textarea
      v-model="feedback"
      class="form-control form-control-flush"
      rows="5"
      placeholder="Type your response here..."
      style="resize: none"
    ></textarea>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { debounce } from 'lodash-es'
export default {
  name: 'ShortAnswer',
  data: () => ({
    feedback: '',
  }),
  computed: {
    ...mapState([
      'customQuestionList',
      'currentQuestionIndex',
      'customQuestionAnswers',
    ]),
  },
  methods: {
    ...mapMutations(['storeAnswer', 'setQuestionStartTime']),
  },
  watch: {
    feedback: debounce(function () {
      this.storeAnswer(this.feedback.trim())
      this.$emit('validate')
    }, 300),
  },
  mounted() {
    this.setQuestionStartTime()
    this.feedback = this.customQuestionAnswers[this.currentQuestionIndex] || ''
  },
}
</script>
