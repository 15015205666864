<template>
  <div class="menu">
    <!-- <div class="card">
      <div class="card-body text-left">
        <h2 class="card-title text-center text-primary mt-2">Order Menu</h2>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Menu',
  computed: {
    ...mapState(['location', 'mixpanelPayload']),
  },
  methods: {
    openMenu() {
      const { menuLink } = this.location

      this.$mixpanel.track('Opening a Menu', {
        ...this.mixpanelPayload,
        url: menuLink,
      })

      if (!menuLink) throw Error('Missing menu link')

      window.location.href = menuLink

      // if (platform.orderUsesPopup) {
      // window.open(menuLink, '_blank')
      // } else {
      //   window.location.href = platform.orderLink
      // }
    },
  },
  mounted() {
    // this.$mixpanel.track('Menu Platform Prompt', {
    //   ...this.mixpanelPayload,
    // })
    this.openMenu()
  },
}
</script>

<style scoped lang="scss">
.menu {
  max-width: 300px;
  margin: auto;
}
</style>
