import PromoService from '../services/PromoService'

export default {
  namespaced: true,

  state: {
    promos: undefined,
    response: undefined,
  },
  mutations: {
    setResponse(state, response) {
      state.response = response
    },
  },
  actions: {
    async sendIncentive({ commit }, payload) {
      const response = await PromoService.sendIncentive(payload)
      commit('setResponse', response)
    },
  },
}
