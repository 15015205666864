<template>
  <div class="voucher mb-2">
    <div class="card">
      <div class="card-body text-center">
        <a href="#" class="avatar avatar-md card-avatar card-avatar-top">
          <i
            :class="[
              'fas',
              selectIncentive.type === 'sweepstakes' ? 'fa-dice' : 'fa-gift',
            ]"
            style="font-size: 3rem"
          ></i>
        </a>

        <h2 class="card-header-title">
          {{ incentiveConfirmation }}
          <span class="text-primary">{{ selectIncentive.prize }}.</span>
        </h2>
      </div>

      <div v-if="selectIncentive.type === 'sweepstakes'" class="card-footer">
        <small>{{ notificationOfWinnerText }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, createNamespacedHelpers } from 'vuex'
import { getPlatformDetails } from '@/lib/platforms'

const CompanyModule = createNamespacedHelpers('CompanyModule')

export default {
  name: 'EnteredGiveaway',
  computed: {
    ...mapGetters(['selectCustomOptions']),
    ...CompanyModule.mapGetters(['selectIncentive']),
    incentiveConfirmation() {
      switch (this.selectIncentive.type) {
        case 'instant':
          return this.selectCustomOptions.notificationOfInstantDeliveryText || `Look out for a text from us shortly with`
        case 'sweepstakes':
        default:
          return this.selectCustomOptions.entryNotificationText || `You've been entered to win`
      }
    },
    notificationOfWinnerText() {
      return this.selectCustomOptions.notificationOfWinnerText || `You'll receive a text message if you win.`
    }
  }
}
</script>

<style scoped lang="scss">
.voucher {
  max-width: 300px;
  margin: auto;
}
.separator {
  width: 100%;
  margin-top: 4px;
}
</style>
