<template>
  <div>
    <h1>Account</h1>
  </div>
</template>

<script>
export default {
  name: 'RewardsAccount',
}
</script>

<style scoped lang="scss"></style>
