<template>
  <div :class="[centerContent ? 'container' : '']">
    <div :class="[centerContent ? 'row justify-content-center' : '']">
      <div
        :class="[
          centerContent ? 'col-12 col-md-7 col-lg-6 col-xl-6' : '',
          { 'text-center': centerText },
        ]"
      >
        <FadeTransition>
          <FullLoader key="loader" v-if="loading || !initialized" />
          <div key="content" v-else>
            <Alert v-if="errorMessage" type="alert-danger">{{
              errorMessage
            }}</Alert>
            <slot v-else />
          </div>
        </FadeTransition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, createNamespacedHelpers } from 'vuex'
import Alert from '@/components/MainContent/Alert'
import FullLoader from '@/components/MainContent/FullLoader'
import FadeTransition from '@/components/Transitions/FadeTransition'
const CompanyModule = createNamespacedHelpers('CompanyModule')
const LocationModule = createNamespacedHelpers('LocationModule')
const I18nModule = createNamespacedHelpers('i18n')

export default {
  name: 'MainContent',
  props: {
    alignDefault: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    initialized: false,
  }),
  components: {
    Alert,
    FullLoader,
    FadeTransition,
  },
  computed: {
    ...mapGetters(['selectLandingRoute', 'selectHasError', 'selectIsLoading']),
    ...CompanyModule.mapGetters(['selectCompany']),
    ...LocationModule.mapGetters(['selectLocation']),
    centerContent() {
      return !this.$route.meta.isFullscreen
    },
    centerText() {
      return !this.alignDefault
    },
    errorMessage() {
      return this.selectHasError(['DECODE_TOKEN'])?.message && this.$t('common.errors.casual.link_expired')
    },
    loading() {
      return this.selectIsLoading(['DECODE_TOKEN'])
    },
  },
  methods: {
    ...I18nModule.mapActions(['fetchLanguage']),
    ...mapActions(['decodeToken', 'resetErrors']),
    setFavicons() {
      const favicon = document.querySelectorAll(
        "link[rel~='icon'], link[rel~='apple-touch-icon']",
      )
      favicon.forEach((data) => {
        data.href = this.selectCompany?.settings?.logo
      })
    },
  },
  async mounted() {
    await this.resetErrors()
    await this.decodeToken(this.$route.query.token)
    const { language, region, source } = this.$route.query
    await this.fetchLanguage({
      locationId: this.selectLocation?._id,
      companyId: this.selectCompany?._id,
      language,
      region,
      flow: this.$route.meta?.templates,
      source
    })
    if (this.$route.name !== this.selectLandingRoute) {
      this.$router.push({
        name: this.selectLandingRoute,
        query: { ...this.$route.query },
      })
    }
    this.setFavicons()
    this.initialized = true
  },
}
</script>

<style scoped lang="scss">
.container {
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
