<template>
  <div class="rewards-offstate">
    <img src="@/assets/loyalty/loyalty-finish.png" />
    <h1 class="mb-3">You're in!</h1>
    <h3>Rewards are coming your way.</h3>
  </div>
</template>

<script>
export default {
  name: 'RewardsOffstate',
}
</script>

<style scoped lang="scss">
.rewards-offstate {
  text-align: center;

  img {
    width: 330px;
    position: relative;
    left: 5px;
  }
}
</style>
