import Vue from 'vue'

export default {
  saveSurvey({ rating }) {
    return Vue.http.post('v2/surveys/save', { rating })
  },
  modifySurveyRating({ rating }) {
    return Vue.http.put('v2/surveys/modify-rating', { rating })
  },
  submitFeedback({ feedback }) {
    return Vue.http.put('v2/surveys/submit-feedback', { feedback })
  },
  // depracated
  // v1 routes
  get(surveyId) {
    return Vue.http.get(`survey/${surveyId}`)
  },
  getSurvey(payload) {
    return Vue.http.get(`survey/${payload.companyId}/${payload.customerId}`)
  },
  create(payload) {
    return Vue.http.post('survey', payload)
  },
  update(payload) {
    return Vue.http.post('survey/update', payload)
  },
  // v2 routes
  createSurvey({ locationId, rating, rawExperienceId, customerId, orderId }) {
    return Vue.http.post('v2/surveys', { locationId, rating, rawExperienceId, customerId, orderId })
  },
}
