import Vue from 'vue'

export default {
  getGivenPromo({ givenPromoId, customerId, locationId }) {
    return Vue.http.get(`promo/given-promo/${givenPromoId}`, {
      params: { customerId, locationId },
    })
  },
  getUniqueCode({ givenPromoId, customerId, promoType }) {
    return Vue.http.get(`promo/unique-code/${givenPromoId}`, {
      params: { customerId, promoType },
    })
  },
  redeem({ customerId, givenPromoId, redemptionSpot }) {
    return Vue.http.post('promo/redeem', {
      customerId,
      givenPromoId,
      redemptionSpot,
    })
  },
  redirect({ promoId, customerId }) {
    return Vue.http.post('promo/redirect', {
      promoId,
      customerId,
    })
  },
  sendIncentive({ customerId, locationId, incentiveId }) {
    return Vue.http.post('promo/send-incentive', {
      customerId,
      locationId,
      incentiveId,
    })
  },
}
