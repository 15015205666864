<template>
  <div class="reviews-and-actions">
    <div class="card">
      <div class="card-body text-left">
        <a 
          href="#" 
          class="avatar avatar-md card-avatar card-avatar-top" 
          style="pointer-events:none"
          v-if="selectSurvey.rating === 5"
        >
          <img
            src="/theme/img/ratings/5.png"
            class="avatar-img rounded-circle"
            alt="..."
          />
        </a>
        <h2 class="card-title mt-4 text-center text-primary">
          {{ $t('leave_review.thank_you') }}
        </h2>
        <h4 
        :style="{ color: selectCustomOptions.leaveReviewTextColor || '#6e84a3' }" 
        class="card-title text-center" v-if="platforms.length">
          {{ $t('leave_review.invite') }}
        </h4>
      </div>

      <div class="card-footer">
        <div
          role="navigation"
          aria-label="platforms"
          class="row align-items-center"
        >
          <div v-for="platform in platforms" :key="platform._id" class="col-12">
            <button
              @click="navigateToPlatform(platform)"
              class="btn btn-lg btn-block btn-white mb-3 text-left brand"
            >
              <img
                :src="platform.img"
                :alt="`${platform.name} logo`"
                class="avatar-img avatar-xs rounded mr-2"
              />
              <span>{{ platform.name }}</span>
            </button>
          </div>
          <hr class="separator" v-if="showSeparator" />
          <div
            v-for="cta in callToActions"
            :key="cta._id"
            class="mx-3 w-100 mb-3"
          >
            <button
              @click="visitCTA(cta)"
              class="btn btn-block btn-white d-flex align-items-center"
            >
              <img
                v-if="cta.logo"
                :src="cta.logo"
                :alt="`${cta.action} logo`"
                class="avatar-img avatar-xxs rounded mr-3"
              />
              <div class="text-left" style="white-space: normal">
                {{ cta.action }}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, createNamespacedHelpers } from 'vuex'
import { getPlatformDetails } from '@/lib/platforms'

const AnalyticEventModule = createNamespacedHelpers('AnalyticEventModule')
const LocationModule = createNamespacedHelpers('LocationModule')
const ReviewSubscriptionModule = createNamespacedHelpers('ReviewSubscriptionModule')
const SurveyModule = createNamespacedHelpers('SurveyModule')

export default {
  name: 'ReviewsAndActions',
  computed: {
    ...mapGetters(['selectCustomOptions']),
    ...LocationModule.mapGetters(['selectLocation']),
    ...ReviewSubscriptionModule.mapGetters(['selectReviewSubscriptions']),
    ...SurveyModule.mapGetters(['selectSurvey']),
    callToActions() {
      return this.selectLocation.callToActions ? this.selectLocation.callToActions : []
    },
    showSeparator() {
      return (this.callToActions || []).length && this.platforms.length
    },
    platforms() {
      if (this.selectSurvey.rating !== 5) return []
      if (!this.selectReviewSubscriptions?.length) return []
      return (
        this.selectLocation?.subscriptions
          ?.filter((p) => !p.hidden)
          ?.flatMap((platform) => getPlatformDetails(platform))
          ?.sort((a, b) => a.displayOrder - b.displayOrder) ?? []
      )
    }
  },
  methods: {
    ...AnalyticEventModule.mapActions(['createLeftReviewAnalyticEvent']),
    navigateToPlatform(platform) {
      this.createLeftReviewAnalyticEvent({ reviewSubscriptionId: platform._id })
      let newWindow = window.open(platform.reviewUrl, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    },
    visitCTA(cta) {
      let newWindow = window.open(cta.url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    },
  }
}
</script>

<style scoped lang="scss">
.reviews-and-actions {
  max-width: 300px;
  margin: auto;
}
.separator {
  width: 100%;
  margin-top: 4px;
}
</style>
