<template>
  <div class="leave-review">
    <div class="card">
      <canvas id="confetti" class="card-img-top"></canvas>

      <div class="card-body text-left">
        <a href="#" class="avatar avatar-md card-avatar card-avatar-top">
          <img
            src="/theme/img/ratings/5.png"
            class="avatar-img rounded-circle"
            alt="..."
          />
        </a>
        <h2 class="card-title mt-4 text-center text-primary">
          {{ $t('leave_review.thank_you') }}
        </h2>
        <h4
          :style="{ color: customLeaveReview.color || '#6e84a3' }"
          class="card-title text-center"
          v-if="platforms.length || callToActions.length"
        >
          {{ $t('leave_review.invite') }}
        </h4>
      </div>

      <div class="card-footer">
        <div
          role="navigation"
          aria-label="platforms"
          class="row align-items-center"
          v-if="platforms.length"
        >
          <div v-for="platform in platforms" :key="platform._id" class="col-12">
            <button
              @click="review(platform)"
              class="btn btn-lg btn-block btn-white mb-3 text-left brand"
            >
              <img
                :src="platform.img"
                :alt="`${platform.name} logo`"
                class="avatar-img avatar-xs rounded mr-2"
              />
              <span>{{ platform.name }}</span>
            </button>
          </div>
        </div>
        <hr class="separator" v-if="showSeparator" />
        <div v-for="cta in callToActions" :key="cta._id" class="w-100 mb-3">
          <button
            @click="visitCTA(cta)"
            class="btn btn-block btn-white d-flex align-items-center"
          >
            <img
              v-if="cta.logo"
              :src="cta.logo"
              :alt="`${cta.action} logo`"
              class="avatar-img avatar-xxs rounded mr-3"
            />
            <div class="text-left" style="white-space: normal">
              {{ cta.action }}
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfettiGenerator from 'confetti-js'
import { mapState, mapGetters } from 'vuex'
import { getPlatformDetails } from '@/lib/platforms'
import AnalyticsService from '@/services/AnalyticsService'

export default {
  name: 'LeaveReview',
  computed: {
    ...mapState(['location', 'customerId', 'mixpanelPayload']),
    ...mapGetters([
      'callToActions',
      'selectCustomLeaveReview',
      'selectCustomOptions',
    ]),

    platforms() {
      return (
        this.location?.subscriptions
          ?.filter((p) => !p.hidden)
          ?.flatMap((platform) => getPlatformDetails(platform))
          ?.sort((a, b) => a.displayOrder - b.displayOrder) ?? []
      )
    },
    showSeparator() {
      return this.callToActions.length && this.platforms.length
    },
    customLeaveReview() {
      return this.selectCustomLeaveReview
    },
  },
  methods: {
    review(platform) {
      AnalyticsService.event({
        type: 'left-review',
        reviewSubscriptionId: platform._id,
        companyId: this.location.company._id,
        locationId: this.location._id,
        customerId: this.customerId,
      })
      this.$mixpanel.track('Leaving a Review', {
        ...this.mixpanelPayload,
        platform: platform.name,
        url: platform.reviewUrl,
      })
      let newWindow = window.open(
        platform.reviewUrl,
        '_blank',
        'noopener,noreferrer',
      )
      if (newWindow) newWindow.opener = null
    },
    visitCTA(cta) {
      this.$mixpanel.track('Visiting Call To Action', {
        ...this.mixpanelPayload,
        action: cta.action,
        url: cta.url,
      })
      let newWindow = window.open(cta.url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    },
  },
  mounted() {
    const confettiSettings = { target: 'confetti' }
    const confetti = new ConfettiGenerator(confettiSettings)
    confetti.render()
    this.$mixpanel.track('Leave Review Prompt', {
      ...this.mixpanelPayload,
    })
  },
}
</script>

<style scoped lang="scss">
.leave-review {
  max-width: 300px;
  margin: auto;
}
.separator {
  width: 100%;
  margin-top: 4px;
}
</style>
