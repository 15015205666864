<template>
  <div>
    <div v-if="errMessage" class="alert alert-danger">
      {{ errMessage }}
    </div>
  </div>
</template>

<script>
import PromoService from '@/services/PromoService'

export default {
  name: 'PromoRedirect',
  data: () => ({
    errMessage: null,
  }),
  async mounted() {
    const { promoId, locationId, customerId } = this.$route.params

    try {
      const response = await PromoService.redirect({
        promoId,
        customerId,
      })

      const { givenPromoId } = response.body

      this.$router.push({
        name: 'redeem',
        params: { customerId, locationId, givenPromoId },
      })

      await this.$nextTick()
      window.location.reload()
    } catch (err) {
      this.errMessage = err.body && err.body.message
    }
  },
}
</script>
