<template>
  <div class="loyalty-cover-wrapper">
    <div class="loyalty-cover text-center" :style="loyaltyCoverStyles">
      <div class="overlay" :style="overlayStyles"></div>
      <div class="content">
        <BrandingHeader centered light />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BrandingHeader from '@/components/Modules/Branding/BrandingHeader'

export default {
  name: 'LoyaltyCover',
  components: {
    BrandingHeader,
  },
  computed: {
    ...mapGetters('loyalty', ['coverPhoto']),
    borderRadiusStyles() {
      return {
        borderTopLeftRadius: this.$mq === 'sm' ? '' : 'calc(0.5rem - 1px)',
        borderTopRightRadius: this.$mq === 'sm' ? '' : 'calc(0.5rem - 1px)',
      }
    },
    overlayStyles() {
      return {
        background: `rgb(0,0,0, ${this.coverPhoto ? 0.5 : 0}`,
        ...this.borderRadiusStyles,
      }
    },
    loyaltyCoverStyles() {
      return {
        color: 'red',
        backgroundImage: `url('${
          this.coverPhoto || require('@/assets/loyalty/loyalty-cover.png')
        }')`,
        ...this.borderRadiusStyles,
      }
    },
  },
}
</script>

<style scoped lang="scss">
.loyalty-cover-wrapper {
  height: 125px;
  overflow: visible;
}
.loyalty-cover {
  height: 190px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .content {
    z-index: 1;
  }
}
</style>
