<template>
  <MainContent :fullScreen="true">
    <div v-if="validConfiguration" class="d-flex flex-row">
      <div>
        <BrandingHeader :showCompanyInfo="false" />

        <qrcode
          :value="sourceUrl"
          class="mb-3 qr-vp-lg"
          v-if="!isShortWindow"
        />

        <div v-if="incentive">
          <div class="vp-md text-center" style="white-space: nowrap">
            {{ incentivePreText }}
          </div>
          <div>
            <div class="vp-lg font-weight-bold" style="color: black">
              {{ incentive }}
            </div>
          </div>
        </div>
      </div>
      <div class="ml-4" v-if="isShortWindow">
        <qrcode :value="sourceUrl" class="mb-3 qr-vp-lg" />
      </div>
    </div>

    <div class="text-danger" v-else>
      Error: Please verify display configuration
    </div>
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import BrandingHeader from '@/components/Modules/Branding/BrandingHeader'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import { mapGetters } from 'vuex'

export default {
  name: 'QRPage',
  components: {
    MainContent,
    BrandingHeader,
    qrcode: VueQrcode,
  },
  data: () => ({
    isShortWindow: false,
  }),
  mounted() {
    if (window.innerHeight < 670) {
      this.isShortWindow = true
    }
    const handler = (function (context) {
      return function () {
        context.isShortWindow = window.innerHeight < 670 ? true : false
      }
    })(this)
    window.onresize = handler
  },
  computed: {
    ...mapGetters(['selectCompany']),
    sourceUrl() {
      return `https://survey.ovationup.com/?token=${this.$route.query.token}`
    },
    authToken() {
      return ''
    },
    incentiveType() {
      return this.selectCompany?.defaultIncentive?.type
    },
    incentive() {
      return this.selectCompany?.defaultIncentive?.prize
    },
    incentivePreText() {
      if (this.incentiveType === 'sweepstakes') {
        return 'Scan for a chance to win '
      }
      // else if (this.incentiveType === 'instant') {
      //   return 'Scan to receive '
      // }
      return ''
    },
    validConfiguration() {
      return this.$route.query.token ? true : false
    },
  },
}
</script>

<style scoped lang="scss">
.vp-md {
  font-size: calc(15px + 2vw);
}
.vp-lg {
  font-size: calc(20px + 2.4vw);
}
.qr-vp-lg {
  width: calc(256px + 4.5vw) !important;
  height: calc(256px + 4.5vw) !important;
}
</style>
