<template>
  <div role="listbox" class="feedback-options mb-4">
    <div
      role="option"
      @click="select(option)"
      v-for="option in options"
      :key="option.value"
      class="feedback-option avatar"
      :aria-selected="focusedValue === option.value"
      :aria-label="`rating ${option.value}`"
      :class="{ unfocused: focusedValue && focusedValue !== option.value }"
    >
      <img
        :alt="`rating ${option.value} emoji`"
        :src="getEmojiSource(option)"
        class="avatar-img enlarge"
        @error="handleImgError(option)"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'FeedbackOptions',
  props: ['focusedValue'],
  data: () => ({
    disabled: false,
    defaultOptions: [
      { img: '1', value: 1 },
      { img: '2', value: 2 },
      { img: '3', value: 3 },
      { img: '4', value: 4 },
      { img: '5', value: 5 },
    ],
  }),
  computed: {
    ...mapGetters(['selectMixpanelPayload']),
    ...mapState(['customOptions', 'location']),
    options() {
      return Object.keys(this.customOptions).length &&
        this.customOptions.options?.length
        ? this.customOptions.options
        : this.defaultOptions
    },
  },
  methods: {
    handleImgError(option) {
      this.$mixpanel.track('EmojiRenderError', {
        ...this.selectMixpanelPayload,
        customOptionsId: this.customOptions?._id || 'no custom options',
        ...(this.customOptions?.options
          ? this.customOptions.options.reduce((acc, curr) => {
              const key = 'emojiOption' + curr.value
              acc[key] = JSON.stringify(curr)
              return acc
            }, {})
          : []),
        emojiNumber: option.img || option.value,
        component: 'FeedbackOptions',
      })
    },
    select(option) {
      if (!this.disabled) {
        this.disabled = true
        this.$emit('selected', option.value)
        let self = this
        setTimeout(() => {
          self.disabled = false
        }, 2000)
      }
    },
    getEmojiSource(option) {
      if (this.customOptions && Object.values(this.customOptions).length) {
        return option.emoji || '/theme/img/ratings/' + option.value + '.png'
      }
      return '/theme/img/ratings/' + option.img + '.png'
    },
  },
}
</script>

<style scoped lang="scss">
.feedback-option {
  &.unfocused {
    opacity: 0.2;
    transform: scale(0.9);
  }
}
.enlarge {
  transform: scale(1.05);
}
</style>
