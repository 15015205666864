<template>
  <MainContent>
    <BrandingHeader />
    <Loyalty />
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import BrandingHeader from '@/components/Modules/Branding/BrandingHeader'
import Loyalty from '@/components/Modules/Loyalty/Loyalty'

export default {
  name: 'LoyaltyPage',
  components: {
    MainContent,
    Loyalty,
    BrandingHeader,
  },
}
</script>

<style scoped lang="scss"></style>
