<template>
  <div class="px-1" :class="{ hiding: !showing }" id="pbar">
    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        style="background-color: var(--primary)"
        :style="`width: ${progress}%`"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'QuestionProgressBar',
  data: () => ({
    showing: true,
  }),
  computed: {
    ...mapState([
      'customQuestionList',
      'currentQuestionIndex',
      'finishedAllQuestions',
    ]),
    progress() {
      return (
        Math.round(
          (this.currentQuestionIndex / this.customQuestionList.length) * 100,
        ) || 5
      )
    },
  },
  watch: {
    finishedAllQuestions: {
      immediate: true,
      async handler() {
        if (this.finishedAllQuestions)
          setTimeout(() => {
            this.showing = false
          }, 1000)
      },
    },
  },
}
</script>

<style scoped>
#pbar {
  transition: visibility 0.5s, opacity 0.5s linear;
}
#pbar.hiding {
  visibility: hidden;
  opacity: 0;
}
</style>
