<template>
  <MainContent />
</template>

<script>
import MainContent from '@/components/MainContent/MainContentV2'

export default {
  name: 'LandingView',
  components: {
    MainContent
  }
}
</script>