<template>
  <div class="feedback-options mt-3">
    <div
      v-for="index in 5"
      :key="index"
      @click="select(index)"
      class="feedback-option avatar"
      :class="{ unfocused: rating && rating !== index }"
    >
      <img
        :src="getEmojiSource(index)"
        :alt="`rating ${index} emoji`"
        class="avatar-img enlarge"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'RatingScale',
  data: () => ({
    rating: '',
  }),
  computed: {
    ...mapState([
      'customQuestionList',
      'currentQuestionIndex',
      'customQuestionAnswers',
    ]),
  },
  methods: {
    ...mapMutations(['storeAnswer', 'setQuestionStartTime']),
    select(rating) {
      this.rating = rating
      this.storeAnswer(this.rating)
      this.$emit('validate')
    },
    getEmojiSource(index) {
      // to add custom emoji options later see 'FeedbackOptions.vue'
      return `/theme/img/ratings/${index}.png`
    },
  },
  mounted() {
    this.setQuestionStartTime()
    this.rating = this.customQuestionAnswers[this.currentQuestionIndex] || ''
  },
}
</script>

<style scoped lang="scss">
.feedback-option {
  &.unfocused {
    opacity: 0.2;
    transform: scale(0.9);
  }
}
.enlarge {
  transform: scale(1.05);
}
</style>
