<template>
  <MainContent :key="routeRefreshKey">
    <BrandingHeader v-if="!hideBranding" />
    <PickSurveyLocation v-if="pickLocationFist" />
    <LeaveSurvey v-else @hide-branding="hideBrandingHandler" />
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import BrandingHeader from '@/components/Modules/Branding/BrandingHeader'
import LeaveSurvey from '@/components/Modules/Locations/LeaveSurvey'
import PickSurveyLocation from '@/components/Modules/Locations/PickSurveyLocation'

export default {
  name: 'LeaveSurveyPage',
  components: {
    MainContent,
    LeaveSurvey,
    BrandingHeader,
    PickSurveyLocation,
  },
  data: () => ({
    hideBranding: false,
  }),
  mounted() {
    // TODO: this is a hard coded fix for lehiBakery who printed all the same qr codes and now is opening
    // a second location. In the future we can get rid of this logic when they have proper qr codes per location
    const { locationId } = this.$route.params
    const { redirected, source, language } = this.$route.query
    const lehiBakeryFirstLocationId = '642ef9e9bf053b693888f8b8'
    const lehiBakeryCompanyId = '642ef9d3d8972b50801d2d4a'
    if (locationId === lehiBakeryFirstLocationId && !redirected) {
      this.$router.push({
        name: 'company-leave-survey',
        params: { companyId: lehiBakeryCompanyId },
        query: { source, language, redirected: true },
      })
    }
  },
  computed: {
    routeRefreshKey() {
      return this.$route.query.redirected || 0
    },
    pickLocationFist() {
      return this.$route.params.companyId != null
    },
  },
  methods: {
    hideBrandingHandler(hide) {
      this.hideBranding = hide === true ? true : false
    },
  },
}
</script>

<style scoped lang="scss"></style>
