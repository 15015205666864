<template>
  <div>
    <div
      class="text-center card-body pl-0 pr-0"
      :style="{ opacity: disabled ? 0.5 : 1 }"
    >
      <div @click="choose('off-premise')" class="option">
        <i class="fas fa-desktop text-primary" style="font-size: 50px"></i>
        <p class="mb-0 pt-3">{{ $t('redemption_location.online') }}</p>
      </div>
      <hr />
      <div @click="choose('in-store')" class="pt-1 option">
        <i class="fas fa-store-alt text-primary" style="font-size: 50px"></i>
        <p class="mb-0 pt-3">{{ $t('redemption_location.store') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'RedemptionChooseLocation',
  data: () => ({
    disabled: false,
  }),
  mounted() {
    this.$mixpanel.track('RedemptionChooseLocation', {
      ...this.selectMixPanelPayload,
      customer: this.selectCustomer?._id,
    })
  },
  computed: {
    ...mapGetters(['selectMixPanelPayload', 'selectCustomer']),
  },
  methods: {
    choose(redemptionSpot) {
      if (this.disabled) return
      this.disabled = true
      this.$emit('choose', redemptionSpot)
    },
  },
}
</script>

<style scoped>
.option:hover {
  cursor: pointer;
}
</style>
