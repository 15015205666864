import LoyaltyService from '@/services/LoyaltyService'
const pluralize = require('pluralize')

export default {
  namespaced: true,
  state: {
    loyalty: {
      pointLabel: '',
      rewards: [],
      activity: [],
    },
    transitioningLoyalty: false,
    redeemingReward: undefined,
  },
  getters: {
    availablePoints(state) {
      return state.loyalty.activity.reduce(
        (totalAvailablePoints, currentActivity) => {
          return totalAvailablePoints + currentActivity.points
        },
        0,
      )
    },
    pastActivity(state) {
      return state.loyalty.activity.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
      )
    },
    upcomingRewards(state) {
      return state.loyalty.rewards.sort((a, b) => a.cost - b.cost)
    },
    pointLabel(state) {
      return state.loyalty.pointLabel
    },
    costTitle(state, getters) {
      return (cost) => {
        return `${cost} ${
          cost > 1 ? pluralize(getters.pointLabel) : getters.pointLabel
        }`
      }
    },
    coverPhoto(state) {
      return state.loyalty.coverPhoto
    },
    isIntegrated(state) {
      return state.loyalty.isIntegrated
    },
    noRewards(state) {
      return !state.loyalty.activity.length
    },
    hasRewards(state) {
      return state.loyalty.activity.length
    },
  },
  mutations: {
    setRedeemingReward(state, reward) {
      state.redeemingReward = reward
    },
    setLoyalty(state, loyalty) {
      state.loyalty = loyalty
    },
    setTransitioningLoyalty(state, transitioning) {
      state.transitioningLoyalty = transitioning
    },
  },
  actions: {
    async startRewardRedemption({ commit }, reward) {
      commit('setRedeemingReward', reward)
      commit('setTransitioningLoyalty', true)
      await new Promise((resolve) => setTimeout(resolve, 105))
    },
    async addCard(__, { customerId, companyId, lastFour }) {
      await LoyaltyService.addCard({
        customerId,
        companyId,
        lastFour,
      })
    },
    async redeemReward(
      __,
      { customerId, companyId, locationId, points, title },
    ) {
      await LoyaltyService.redeemReward({
        customerId,
        companyId,
        locationId,
        points,
        title,
      })
    },
    async getLoyalty({ commit }, { customerId, companyId }) {
      const response = await LoyaltyService.getLoyalty({
        customerId,
        companyId,
      })
      const { loyalty } = response.data
      commit('setLoyalty', loyalty)
    },
  },
}
