<template>
  <MainContent>
    <FullLoader key="l-loader"/>
  </MainContent>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import MainContent from '@/components/MainContent/MainContent'
import FullLoader from '@/components/MainContent/FullLoader'
const LocationModule = createNamespacedHelpers('LocationModule')

export default {
  name: 'LeaveSurveyLocationLookupPage',
  components: {
    MainContent,
    FullLoader,
  },
  methods: {
    ...LocationModule.mapActions(['locationLookup'])
  },
  async mounted() {
    const locationId = await this.locationLookup(this.$route.query)
    if (locationId) {
      this.$router.push({ 
        path: `/leave-survey/${locationId}`, 
        query: this.$route.query
      });
    }
  }
}
</script>

<style scoped lang="scss"></style>
