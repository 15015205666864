<template>
  <MainContent>
    <BrandingHeader />
    <div v-if="completed">
      <h1 class="text-center mb-5">Thank you!</h1>
    </div>
    <div class="giveaway" v-else>
      <h1 class="text-center mb-5">
        How can we keep <br />
        in touch?
      </h1>
      <form @submit.prevent="enter">
        <div class="row">
          <div class="text-left form-group col-12 inline-block">
            <label for="phone">Mobile number</label>
            <input
              id="phone"
              v-model="phone"
              ref="phone"
              @input="onPhoneType"
              type="tel"
              class="form-control"
              placeholder="(000) 000-0000"
              v-mask="'(###) ###-####'"
              autocomplete="tel"
              :disabled="isLoading || disablePhoneInput"
            />
          </div>
        </div>

        <FadeTransition>
          <div>
            <div class="row">
              <div class="text-left form-group col-12 inline-block">
                <label for="name">Full name</label>
                <input
                  id="name"
                  ref="name"
                  v-model="name"
                  type="text"
                  class="form-control"
                  placeholder="John doe"
                  autocomplete="name"
                />
              </div>
            </div>

            <div class="row">
              <div class="text-left form-group col-12 inline-block">
                <label for="email">
                  Email address
                  <span class="text-muted">
                    {{ location.requireEmails ? 'required' : 'optional' }}
                  </span>
                </label>
                <input
                  id="email"
                  v-model="email"
                  type="email"
                  class="form-control"
                  placeholder="name@address.com"
                  autocomplete="email"
                />
              </div>
            </div>

            <div class="text-left mb-4">
              <div class="tight-text">
                {{ legalText }} See our
                <a href="https://ovationup.com/privacy-policy/" target="_blank" style="color: blue !important;">
                  Privacy Policy </a
                >.
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div v-if="errMessage" class="alert alert-danger" role="alert">
                  {{ errMessage }}
                </div>
                <button
                  type="submit"
                  class="btn btn-lg btn-block btn-primary mb-3"
                  :class="{ 'is-loading': isLoading }"
                  :disabled="!isFormValid"
                >
                  {{ submitText }}
                </button>
              </div>
            </div>
          </div>
        </FadeTransition>
      </form>
    </div>
  </MainContent>
</template>

<script>
// This component is just a placeholder for displaying to bandwidth. It doesn't send any requests
// to save any data to the backend on purpose.

import MainContent from '@/components/MainContent/MainContent'
import BrandingHeader from '@/components/Modules/Branding/BrandingHeader'
import { mapState, mapGetters, mapMutations } from 'vuex'
import CustomerService from '@/services/CustomerService'
import FadeTransition from '@/components/Transitions/FadeTransition'
import { createNamespacedHelpers } from 'vuex'
const PromoModule = createNamespacedHelpers('promo')

export default {
  name: 'FauxInfoForm',
  components: {
    MainContent,
    BrandingHeader,
    FadeTransition,
  },
  data: () => ({
    name: '',
    phone: '',
    email: '',
    completed: false,
    isLoading: false,
    checkedPhone: false,
    disablePhoneInput: false,
    errMessage: '',
  }),
  computed: {
    ...mapState(['location', 'company', 'leftFeedback', 'customer', 'source']),
    ...mapGetters([
      'incentive',
      'selectCustomSubmitText',
      'selectCustomOptions',
    ]),
    isGrubhub() {
      return false
    },
    legalText() {
      if (this.isGrubhub) {
        return `
          By clicking ${this.submitText}, I consent to receive phone calls, text 
          messages, and/or emails from ${this.company.name}, 
          about my order at the phone number/email address I provided. I understand 
          these calls/messages/emails may be generated using an automated technology.
        `
      } else {
        return `
          By clicking ${this.submitText}, I consent to receive phone calls, text
          messages, and/or emails from ${this.company.name},
          regarding their products and services, at the phone number/email
          address I provided. I understand these calls/messages/emails may be
          generated using an automated technology.
        `
      }
    },
    isFormValid() {
      const isPhoneValid = this.validatePhone(this.phone)
      let isFormValid = !this.isLoading && isPhoneValid

      if (this.location.requireEmails) {
        isFormValid &= !!this.email
      }

      if (this.email) {
        isFormValid &= this.validateEmail(this.email)
      }

      return isFormValid
    },
    submitText() {
      return this.selectCustomSubmitText
        ? this.selectCustomSubmitText
        : this.source === 'checkin' || this.incentive.type === 'instant'
        ? 'Submit'
        : 'Submit'
    },
    incentivePrizeCTA() {
      switch (this.incentive.type) {
        case 'instant':
          return (
            this.selectCustomOptions.instantIncentivePreText ||
            `Enter your information to receive`
          )
        case 'sweepstakes':
        default:
          return ''
      }
    },
  },
  async mounted() {
    if (this.customer && this.customer.phone) {
      this.phone = this.formatPhone(this.customer.phone)
      this.disablePhoneInput = true
      this.onPhoneType()
    }
  },
  methods: {
    ...mapMutations(['setCustomerId']),
    ...PromoModule.mapActions(['sendIncentive']),

    formatPhone(phone) {
      phone = phone.replace('+', '').replace(/\D+/g, '')
      if (phone.charAt(0) === '1') {
        phone = phone.substr(1)
      }
      return phone
    },

    validateEmail(email) {
      const emailRegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/
      return emailRegExp.test(email.toLowerCase())
    },

    validatePhone(phone) {
      const stripped = (phone || '').replace(/\D/g, '')
      return /^\d{10}$/g.test(stripped)
    },

    async onPhoneType() {
      if (this.phone.length === 11) {
        this.phone = this.formatPhone(this.phone)
      }

      if (this.phone.length === 14) {
        try {
          // const response = await CustomerService.getByPhone({
          //   phone: this.phone,
          // })
          // const { customer } = response.body
          // this.email = customer.email
          // this.name = customer.name
        } catch (err) {
          console.error(err)
        } finally {
          this.checkedPhone = true

          if (!this.name) {
            this.$refs.name.focus()
          } else if (this.$refs.phone) {
            this.$refs.phone.blur()
          }
        }
      }
    },
    async enter() {
      this.completed = true
    },
  },
}
</script>

<style scoped lang="scss">
.giveaway {
  max-width: 300px;
  margin: auto;
}
.tight-text {
  display: block;
  line-height: 120% !important;
  font-size: 11px !important;
}
</style>
