var sheet = (function () {
  // Create the <style> tag
  const style = document.createElement('style')
  style.setAttribute('type', 'text/css')
  style.classList.add('custom-theme')
  // WebKit hack :(
  style.appendChild(document.createTextNode(''))
  // Add the <style> element to the head
  document.head.appendChild(style)

  return style.sheet
})()

function addCSSRule(sheet, selector, rules, index) {
  if ('insertRule' in sheet) {
    if (sheet.cssRules[index]) {
      sheet.deleteRule(index)
    }
    sheet.insertRule(selector + '{' + rules + '}', index)
  } else if ('addRule' in sheet) {
    // legacies props
    if (sheet.cssRules[index]) {
      sheet.removeRule(index)
    }
    sheet.addRule(selector, rules, index)
  }
}

export default {
  applyColor(color) {
    document.documentElement.style.setProperty('--primary', color)
    // Buttons
    addCSSRule(sheet, '.text-primary, a', 'color: ' + color + ' !important', 0)
    addCSSRule(
      sheet,
      '.btn.btn-primary',
      'background: ' + color + ' !important',
      1,
    )
    addCSSRule(
      sheet,
      '.btn.btn-outline-primary',
      'color: ' + color + ' !important',
      2,
    )
    addCSSRule(
      sheet,
      '.btn.btn-outline-primary',
      'border-color: ' + color + ' !important',
      3,
    )
    addCSSRule(
      sheet,
      '.btn.btn-outline-primary',
      'background: ' + '#fff' + ' !important',
      4,
    )
    addCSSRule(
      sheet,
      '.card.card-primary',
      'background: ' + color + ' !important',
      5,
    )
    addCSSRule(
      sheet,
      '.alert-primary',
      'background: ' + color + ' !important',
      6,
    )
    addCSSRule(
      sheet,
      '.alert-primary',
      'border-color: ' + color + ' !important',
      7,
    )
    addCSSRule(
      sheet,
      '.btn.btn-primary',
      'border-color: ' + color + ' !important',
      8,
    )
  },
}
