<template>
  <div id="app" :class="[centerApp ? 'd-flex align-items-center' : '']">
    <SlideFadeTransition>
      <router-view />
    </SlideFadeTransition>
  </div>
</template>

<script>
import SlideFadeTransition from '@/components/Transitions/SlideFadeTransition'

export default {
  name: 'App',
  components: {
    SlideFadeTransition,
  },
  computed: {
    centerApp() {
      return !this.$route.meta.isFullscreen
    },
  },
}
</script>

<style lang="scss">
#app {
  width: 100%;
  min-height: 100%;
}
</style>
