<template>
  <div>
    <canvas id="confetti" class="card-img-top"></canvas>
    <div v-if="isRedeemed" class="redeem card card-primary mb-3">
      <div class="card-body pt-3 pb-3">
        <p class="mb-1">expires in</p>
        <h1 class="mb-0">
          <CountdownTimer :time="15 * 60 * 1000 - 1" @end="goBack" />
        </h1>
      </div>
    </div>
    <div class="redeem card">
      <div class="card-header text-left">
        <h1 class="text-center mt-4 text-primary text-capitalize">
          {{ title }}
        </h1>
      </div>
      <div class="text-center card-body">
        <div>
          <p class="mb-0" v-html="description"></p>
        </div>
      </div>
      <div v-if="!isRedeemed" class="card-footer">
        <button
          @click="nextStep"
          class="btn btn-primary btn-lg btn-block mb-3 mt-3"
        >
          {{ buttonText }}
        </button>
      </div>
      <div class="card-footer" v-if="!isRedeemed">
        <small class="text-muted"
          >Can only be redeemed once. Make sure you are on-site before
          redeeming.</small
        >
      </div>
    </div>
  </div>
</template>

<script>
import ConfettiGenerator from 'confetti-js'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import CountdownTimer from '@/components/Modules/Redeem/CountdownTimer'

export default {
  name: 'RedeemReward',
  components: {
    CountdownTimer,
  },
  data: () => ({
    isRedeemed: false,
    isConfirming: false,
  }),
  computed: {
    ...mapState('loyalty', {
      reward: 'redeemingReward',
    }),
    ...mapState(['customer', 'location']),
    ...mapGetters('loyalty', ['costTitle']),
    title() {
      if (this.reward?.title) {
        return this.reward?.title
      } else {
        this.$mixpanel.track('MissingRewardData', {
          ...this.selectMixPanelPayload,
          customer: this.selectCustomer?._id,
          location: this.location._id,
          rewardData: this.reward,
        })
        return ''
      }
    },
    description() {
      if (!this.isRedeemed && !this.isConfirming) {
        return `Are you sure you want to spend ${this.costTitle(
          this.reward?.cost
        )} to receive this reward?`
      } else if (this.isConfirming) {
        return 'You will have <strong class="text-primary">15 minutes</strong> to show this to the cashier.'
      } else {
        return 'Thank you for your loyalty!'
      }
    },
    buttonText() {
      if (!this.isRedeemed && !this.isConfirming) {
        return `Use ${this.costTitle(this.reward?.cost)}`
      } else if (this.isConfirming) {
        return "Yes, I'm ready to redeem"
      }
      return ''
    },
  },
  methods: {
    ...mapMutations('loyalty', ['setTransitioningLoyalty']),
    ...mapActions('loyalty', ['redeemReward']),
    redeem() {
      this.redeemReward({
        points: this.reward?.cost,
        title: this.reward?.title,
        customerId: this.customer._id,
        companyId: this.location.company?._id,
        locationId: this.location._id,
      })
      this.isConfirming = false
      this.isRedeemed = true
      this.showConfetti()
    },
    nextStep() {
      if (!this.isRedeemed && !this.isConfirming) {
        this.isConfirming = true
      } else if (this.isConfirming) {
        this.redeem()
      }
    },
    showConfetti() {
      const confettiSettings = { target: 'confetti' }
      const confetti = new ConfettiGenerator(confettiSettings)
      confetti.render()
    },
    goBack() {
      this.$router.go(-1)
    },
  },
  mounted() {
    this.setTransitioningLoyalty(false)
  },
}
</script>

<style scoped lang="scss">
.redeem {
  max-width: 280px;
  margin: auto;
}

.card-primary {
  background-color: var(--primary);
  color: #fff;

  h1 {
    font-size: 30px;
    line-height: 33px;
  }
}
</style>
