<template>
  <div :class="[centerContent ? 'container' : '']">
    <div :class="[centerContent ? 'row justify-content-center' : '']">
      <div
        :class="[
          centerContent && !fullScreen ? 'col-12 col-md-7 col-lg-6 col-xl-6' : '',
          { 'text-center': centerText },
        ]"
      >
        <FadeTransition>
          <FullLoader key="loader" v-if="showLoader" />
          <div key="content" v-else>
            <slot></slot>
            <Alert v-if="errMessage" type="alert-danger">{{
              errMessage
            }}</Alert>
          </div>
        </FadeTransition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, createNamespacedHelpers} from 'vuex'
import Alert from '@/components/MainContent/Alert'
import FullLoader from '@/components/MainContent/FullLoader'
import FadeTransition from '@/components/Transitions/FadeTransition'
import SurveyService from '@/services/SurveyService'

const I18nModule = createNamespacedHelpers('i18n')

export default {
  name: 'MainContent',
  props: {
    alignDefault: {
      type: Boolean,
      default: false,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Alert,
    FullLoader,
    FadeTransition,
  },
  data: () => ({
    errMessage: '',
    showLoader: true,
  }),
  computed: {
    ...mapState(['location', 'customOptionsChecked', 'company']),
    centerContent() {
      return !this.$route.meta.isFullscreen
    },
    centerText() {
      return !this.alignDefault
    },
  },
  methods: {
    ...I18nModule.mapActions(['fetchLanguage']),
    ...mapActions([
      'getCompany',
      'getLocation',
      'getCustomer',
      'getCustomOptions',
      'getCustomQuestionList',
      'getCustomQuestionSettings',
      'setOrderId',
      'setRating',
      'setRawExperienceId'
    ]),
    ...mapMutations(['setCustomer', 'setCompany']),
    async getCustOpts() {
      if (!this.customOptionsChecked)
        await this.getCustomOptions({ 
          companyId: this.location?.company?._id || this.company._id 
        })
    },
  },
  async mounted() {
    const { companyId, locationId, surveyId, customerId, listId } =
      this.$route.params

    if (customerId) {
      await this.getCustomer(customerId).catch((err) => {
        console.error(err)
      })
    }

    const { orderId, rating, rawExperienceId, language, region, source } = this.$route.query
    if (orderId) {
      this.setOrderId(orderId)
    }

    if (rawExperienceId) {
      this.setRawExperienceId(rawExperienceId)
    }

    if (rating) {
      this.setRating(rating)
    }

    try {
      if (locationId) {
        await this.getLocation(locationId)
        await Promise.all([
          this.getCustOpts(),
          this.fetchLanguage({locationId: this.location?._id, companyId: this.company?._id, language, region, flow: this.$route.meta?.templates, source})
        ])
      } else if (companyId) {
        await this.getCompany(companyId)
        await Promise.all([
          this.getCustOpts(),
          this.fetchLanguage({locationId: this.location?._id, companyId: this.company?._id, language, region, flow: this.$route.meta?.templates, source})
        ])
      } else if (surveyId) {
        const response = await SurveyService.get(surveyId)
        const { survey } = response.body
        if (survey.customer) this.setCustomer(survey.customer)
        await this.getLocation(survey.location)
        await Promise.all([
          this.getCustOpts(),
          this.fetchLanguage({locationId: this.location?._id, companyId: this.company?._id, language, region, flow: this.$route.meta?.templates, source})
        ])
      } else if (listId) {
        await this.getCustomQuestionList({ listId })
        await this.getCustomQuestionSettings()
      }
    } catch (err) {
      console.log(err)
      await this.fetchLanguage({locationId: this.location?._id, companyId: this.company?._id, language, region, flow: this.$route.meta?.templates, source})
      this.errMessage = `${
        this.$t( 'common.errors.causal.not_found',  [ locationId || companyId ] )
      }.`
    } finally {
      this.showLoader = false
    }
  },
  watch: {
    company() {
      if (this.company && this.company.settings && this.company.settings.logo) {
        const favicon = document.querySelectorAll(
          "link[rel~='icon'], link[rel~='apple-touch-icon']",
        )
        favicon.forEach((data) => {
          data.href = this.company.settings.logo
        })
      }
    },
    location() {
      if (
        this.location &&
        this.location.company &&
        this.company &&
        !this.company._id
      ) {
        this.setCompany(this.location.company)
      }
      if (
        this.location &&
        this.location.company &&
        this.location.company.settings.logo
      ) {
        const favicon = document.querySelectorAll(
          "link[rel~='icon'], link[rel~='apple-touch-icon']",
        )
        favicon.forEach((data) => {
          data.href = this.location.company.settings.logo
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
