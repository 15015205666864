import Config from '@/config'
import store from '../store'

export function interceptor(request) {
  const { api, apiv2, surveyApi } = Config
  request.url = `${
    request.url.startsWith('v2/')
      ? apiv2[process.env.NODE_ENV]
      : request.url.startsWith('survey-app')
        ? surveyApi
        : api
  }/${request.url}`

  if (store.getters.selectToken) {
    request.headers.set('Authorization', store.getters.selectToken)
  }
}
