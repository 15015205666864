// Platform data

const PLATFORMS = {
  doordash: (sourceId) => ({
    name: 'DoorDash',
    img: `/theme/img/platforms/doordash.svg`,
    reviewUrl: `https://www.doordash.com/store/${sourceId}`,
    pageUrl: `https://www.doordash.com/store/${sourceId}`,
  }),
  google: (sourceId) => ({
    name: 'Google',
    img: `/theme/img/platforms/google.svg`,
    reviewUrl: `https://search.google.com/local/writereview?placeid=${sourceId}`,
    pageUrl: `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${sourceId}`,
  }),
  facebook: (sourceId) => ({
    name: 'Facebook',
    img: `/theme/img/platforms/facebook.svg`,
    reviewUrl: `https://www.facebook.com/${sourceId}`,
    pageUrl: `https://www.facebook.com/${sourceId}`,
  }),
  yelp: (sourceId) => ({
    name: 'Yelp',
    img: `/theme/img/platforms/yelp.svg`,
    reviewUrl: `https://www.yelp.com/writeareview/biz/${sourceId}`,
    pageUrl: `https://www.yelp.com/biz/${sourceId}`,
  }),
  foursquare: (sourceId) => ({
    name: 'Foursquare',
    img: `/theme/img/platforms/foursquare.svg`,
    reviewUrl: `https://foursquare.com/v/${sourceId}`,
    pageUrl: `https://foursquare.com/v/${sourceId}`,
  }),
  grubhub: (sourceId) => ({
    name: 'Grubhub',
    img: `/theme/img/platforms/grubhub.svg`,
    reviewUrl: `https://grubhub.com/${sourceId}`,
    pageUrl: `https://grubhub.com/${sourceId}`,
  }),
  opentable: (sourceId) => ({
    name: 'OpenTable',
    img: `/theme/img/platforms/opentable.svg`,
    reviewUrl: `https://www.opentable.com/my/profile/info`,
    pageUrl: `https://www.opentable.com/${sourceId}`,
  }),
  tripadvisor: (sourceId) => ({
    name: 'TripAdvisor',
    img: `/theme/img/platforms/tripadvisor.svg`,
    reviewUrl: `https://www.tripadvisor.com/${sourceId}`,
    pageUrl: `https://www.tripadvisor.com/${sourceId}`,
  })
}

export function getPlatformDetails({ _id, network, slug, reviewSubscription, displayOrder, hidden }) {
  const id = reviewSubscription ? reviewSubscription._id : _id
  const _network = reviewSubscription ? reviewSubscription.network : network
  const _slug = reviewSubscription ? reviewSubscription.slug : slug
  return { _id: id, ...PLATFORMS[_network](_slug), displayOrder, hidden }
}
