<template>
  <div class="branding-header" :class="{ centered, light }">
    <div
      class="avatar avatar-xl mb-4"
      v-if="logo && logo.indexOf('assets.ovationup.com') === -1"
    >
      <img :src="logo" alt="company logo" class="avatar-img rounded-circle" />
    </div>
    <h3 class="text-primary text-center mb-5">
      {{ selectCompany.name }}
    </h3>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('CompanyModule')

export default {
  name: 'BrandingHeader',
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...CompanyModule.mapGetters(['selectCompany']),
    logo() {
      return this.selectCompany?.settings?.logo
    },
  },
}
</script>

<style scoped lang="scss">
.centered {
  h3 {
    margin-bottom: 0px !important;
  }
}

.light {
  h3 {
    color: #fff !important;
  }
}
</style>
