<template>
  <div class="upcoming-rewards">
    <h2 class="mb-3">Rewards</h2>
    <div class="card">
      <div class="card-body">
        <div v-for="(reward, idx) of upcomingRewards" :key="reward.title">
          <UpcomingRewardItem :reward="reward" />
          <hr v-if="idx < upcomingRewards.length - 1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UpcomingRewardItem from './UpcomingRewardItem'

export default {
  name: 'UpcomingRewards',
  components: {
    UpcomingRewardItem,
  },
  computed: {
    ...mapGetters('loyalty', ['upcomingRewards']),
  },
}
</script>

<style scoped lang="scss"></style>
