<template>
  <div class="add-phone">
    <h3 class="display-4 text-center mb-4 question-title">
      {{ location.company.loyalty.callout }}
    </h3>

    <form @submit.prevent="startLoyalty">
      <div class="row">
        <div class="text-left form-group col-12 inline-block">
          <label>Mobile number</label>
          <input
            v-model="phone"
            ref="phone"
            type="tel"
            class="form-control"
            placeholder="(000) 000-0000"
            v-mask="'(###) ###-####'"
            autocomplete="tel"
            :disabled="isLoading"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div v-if="errMessage" class="alert alert-danger" role="alert">
            {{ errMessage }}
          </div>
          <button
            type="submit"
            class="btn btn-lg btn-block btn-primary mb-3"
            :class="{ 'is-loading': isLoading }"
            :disabled="!phone || isLoading"
          >
            Continue
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import CustomerService from '@/services/CustomerService'
import CheckinService from '@/services/CheckinService'

export default {
  name: 'AddPhone',
  components: {},
  data: () => ({
    phone: '',
    errMessage: '',
    isLoading: false,
  }),
  computed: {
    ...mapState(['location', 'mixpanelPayload']),
  },
  methods: {
    ...mapMutations(['setCustomer']),
    async startLoyalty() {
      this.isLoading = true
      this.errMessage = undefined

      this.$mixpanel.track('Created Customer', {
        ...this.mixpanelPayload,
      })

      try {
        const locationId = this.location._id
        const companyId = this.location.company._id

        const response = await CustomerService.create({
          phone: this.phone,
          locationId,
          companyId,
        })

        const { customer } = response.body
        await this.setCustomer(customer)

        await CheckinService.create({
          customerId: customer._id,
          locationId,
          companyId,
        })

        this.$emit('added')
      } catch (err) {
        this.isLoading = false
        this.errMessage = err.body && err?.body?.message
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
