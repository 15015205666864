import AnalyticEventService from '../services/AnalyticEventService'

export default {
  namespaced: true,
  actions: {
    async createLeftReviewAnalyticEvent({ commit }, { reviewSubscriptionId }) {
        try {
            await AnalyticEventService.createLeftReviewAnalyticEvent({ reviewSubscriptionId })
        } catch(e) {
            console.log(e)
        }
    }
  },
}
